export interface GoogleMediaItem {
  createTime: string;
  description: string;
  dimensions: {
    heightPixels: number;
    widthPixels: number;
  };
  googleUrl: string,
  insights: {
    viewCount: string
  },
  locationAssociation: {
    category: string
  },
  mediaFormat: string,
  name: string;
  sourceUrl: string;
  thumbnailUrl: string;
}

export class ModalCategories {
  COVER: GoogleMediaItem[] = [];
  PROFILE: GoogleMediaItem[] = [];
  EXTERIOR: GoogleMediaItem[] = [];
  INTERIOR: GoogleMediaItem[] = [];
  PRODUCT: GoogleMediaItem[] = [];
  AT_WORK: GoogleMediaItem[] = [];
  FOOD_AND_DRINK: GoogleMediaItem[] = [];
  MENU: GoogleMediaItem[] = [];
  COMMON_AREA: GoogleMediaItem[] = [];
  ROOMS: GoogleMediaItem[] = [];
  TEAMS: GoogleMediaItem[] = [];
  ADDITIONAL: GoogleMediaItem[] = [];
  CATEGORY_UNSPECIFIED: GoogleMediaItem[] = [];
}

export interface Categories {
  name: string;
  value: string;
}

export interface ImageRequirement {
  accepts?: string,
  type: string[], 
  min_width: number, 
  min_height: number, 
  max_width?: number,
  max_height?: number,
  min_size: number,
  max_size?: number,
  minRatio?: number
}

export const CATEGORIES_DATA: Categories[] = [
  {name: 'COVER', value: 'COVER'},
  {name: 'VIDEO', value: 'ADDITIONAL'},
  {name: 'PROFILE', value: 'PROFILE'},
  {name: 'EXTERIOR', value: 'EXTERIOR'},
  {name: 'INTERIOR', value: 'INTERIOR'},
  {name: 'PRODUCT', value: 'PRODUCT'},
  {name: 'AT WORK', value: 'AT_WORK'},
  {name: 'FOOD AND DRINK', value: 'FOOD_AND_DRINK'},
  {name: 'MENU', value: 'MENU'},
  {name: 'COMMON AREA', value: 'COMMON_AREA'},
  {name: 'ROOMS', value: 'ROOMS'},
  {name: 'TEAMS PHOTOS', value: 'TEAMS'},
];
