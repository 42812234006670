export const Messages = {
  notifications: {
    // NEW_ACCOUNT_USER: "A new account has been created for admin user {0}",
    USER_ADDED: "User {0} was created with role {1}",
    LOCATION_TOGGLE: "{0} {1} was {2}",
    // LOCATION_CHANGE_PLAN: "{0} {1}, plan was changed to {2}",
  },
  register: {
    SUCCESS: 'Registration complete... redirecting to your dashboard.',
    USER_DOESNT_EXIST: 'You need to register first',
    // RECREATED: 'Your account has been re-created! Welcome back!',
    EMAIL_NOT_VERIFIED: 'You must verify the registration email'
  },
  // reports: {
  //   SHARE_SUCCESS: 'Report shared successfully!'
  // },

  upload: {
    BAD_DIMENSION: 'Image dimension must be less than 2120x1192px.',
    BAD_DIMENSION_DEFINED: 'Image dimension must be less than 250x100px.',
    BAD_DIMENSION_MINIMUM: 'Image dimension must be at least 250x250px.',
    BAD_SIZE: 'File size must be at least 10KB.',
    BAD_TYPE: 'Only PNG or JPG formats are allowed.',
    BAD_TYPES: 'Only {0} format{1} are allowed.',
    BAD_RATIO: 'Image ratio must be 4:3. Please edit and retry or upload a new image.',
    SEND_SUCCESS: 'Successfully sent media to GBP!',
    BAD_DIMENSION_DEFINED_CUSTOM: 'Image dimension must be less than {0}x{1}px.',
    BAD_DIMENSION_MINIMUM_CUSTOM: 'Image dimension must be at least {0}x{1}px.',
    BAD_SIZE_CUSTOM: 'File size must be at least {0}.',
    BAD_MAX_SIZE_CUSTOM: 'File size must be less than {0}.',
  },
  post: {
    PARTIAL_PUBLISH: 'Posts are being published. This can take several minutes. Please refresh after a few minutes',
    PARTIAL_DELETE: 'Posts are being deleted. This can take several minutes. Please refresh after a few minutes',
    PARTIAL_UPDATE: 'Posts are being updated. This can take several minutes. Please refresh after a few minutes',
  }

} as const;

export const string_message = (template: string, data: string[]) : string => 
  template.replace(/{\w+}/g, placeholder => data[Number(placeholder.substring(1, placeholder.length - 1))] || placeholder);

export const formatFileSizes = (size: number, fixed = 0) : string => {
  if (size < 1024) {
    return size + ' bytes';
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(fixed) + 'KB';
  } else {
    return (size / (1024 * 1024)).toFixed(fixed) + 'MB';
  }
}