<div class="dialog__content">
  <div class="dialog__header">
    <h2 class="m--0 txt--xl"><strong>Photos</strong></h2>
  </div>
  <div class="dialog__content dialog__content--gallery" mat-dialog-content>

    <swiper #gallery
            [config]="GALLERY_CONF" 
            [(index)]="index" 
            class="carousel carousel--media">
      <div class="full-image-container" *ngFor="let item of mediaFiles">
        <div>
          <button (click)='openDelete(item)' 
                  matTooltip="Delete" 
                  matTooltipClass="tooltip tooltip--red" 
                  matTooltipPosition="above"
                  class="btn btn-delete btn-delete--gallery">
            <i class="fas fa-trash-alt"></i>
          </button>

          <ng-container *ngIf="item.mediaFormat === 'VIDEO'; else photoImage">
            <!-- Videos need to have a sourceUrl defined to be shown, only available
                 if the video was created thru Maplabs, not thru the GBP dashboard.
                 `googleUrl` is always available but is only a preview image of the
                 video with a 'play' icon drawn over it. 
             -->
            <ng-container *ngIf="!!item.sourceUrl; else videoNoSource">
              <a [href]="item.sourceUrl" target="_blank" rel="noopener noreferrer">
                <img [src]="item?.googleUrl" 
                     [alt]="item?.googleUrl"/>
              </a>
            </ng-container>
            <ng-template #videoNoSource>
              <img [src]="item?.googleUrl" 
                   [alt]="item?.googleUrl"
                   (click)="showCannotPlayVideoModal()"/>
            </ng-template>
          </ng-container>
          <ng-template #photoImage>
            <img [src]="item?.googleUrl" 
                 [alt]="item?.googleUrl"/>
          </ng-template>

        </div>
        <p class="txt--sm" *ngIf="item?.description">{{ item?.description}}</p>
      </div>
    </swiper>

    <swiper #galleryThumbs 
            [config]="GALLERY_THUMBS_CONF"
            (click)="changeGalleryIndex($event)" 
            class="carousel carousel--thumbs">
      <div class="thumb-wrapper" *ngFor="let item of mediaFiles">
          <img [src]="item?.googleUrl" 
               [alt]="item?.googleUrl">
      </div>
    </swiper>

  </div>
</div>

<div mat-dialog-actions class="dialog__footer footer-show-media">
  <span class="alternate-theme d-inline-block mr--10">
      <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">
        Cancel
      </button>
  </span>
</div>
