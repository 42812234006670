<app-skeleton-posts [type]="post_type.toUpperCase()" *ngIf="progressPost"></app-skeleton-posts>

<ng-container *ngIf="!progressPost">
    <ng-container *ngIf="postsExist; else emptyPosts">
        <table mat-table matSort [dataSource]="dataSource" class="table table--lg table--last-row-border table--posted" (matSortChange)="sortByActions($event)">
            <ng-container matColumnDef="img">
            <th mat-header-cell *matHeaderCellDef><strong>Media</strong></th>
            <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('img')">
              <div class="cell__content">
                <div class="table__img" *ngIf="element?.postInfo.media; else noMedia">
                    <img class="img_post"
                        *ngIf="element?.postInfo.media.length > 0"
                        [src]="element?.sourceImage || element?.postInfo.media[0]?.sourceUrl || element?.postInfo.media[0]?.googleUrl || '../../../assets/images/placeholder/NoImage.png' "
                        alt="">
                        <img class="img_post"
                        *ngIf="element?.postInfo.media.length == 0"
                        src="../../../assets/images/placeholder/NoImage.png"
                        alt="">
                    <div id="overlay" *ngIf="false">
                        <p style="margin-top: 6px; margin-right: 10px; text-align: end;">{{element?.postInfo.media.length}} <em class="fas fa-images"></em></p>
                    </div>
                </div>
                <ng-template #noMedia>
                    <div class="table__img txt--center d-flex align-items-center">
                    No image or videos
                    </div>
                </ng-template>
              </div>
            </td>
            </ng-container>

            <ng-container matColumnDef="info">
              <th mat-header-cell *matHeaderCellDef><strong>Description</strong></th>
              <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('info')">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                      <h6 class="m--0"><strong>{{ element.postInfo.topicType | titlecase |topicType}} </strong></h6>
                      <div class="d-inline-flex">
                        <ng-container *ngIf="bulkPost">
                          <ng-container *ngIf="element.locationsRef.length > 0; else noAccounts">
                            <button mat-flat-button 
                                    color="accent" 
                                    [matMenuTriggerFor]="locationMenu" 
                                    class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs" 
                                    [class.bg--yellow]="element?.missingLocations > 0">
                              {{ element?.planned }} locations 
                              <em class="fas fa-angle-down ml--10 mr--0"></em>
                            </button>
                            <mat-menu #locationMenu="matMenu" class="mat-menu--lg">
                              <ng-container *ngIf="element.locationsRef.length > 0">
                                <mat-progress-spinner mode="indeterminate" diameter="15" *ngIf="element.locationsRef.length !== element?.planned">
                                </mat-progress-spinner>
                                <button *ngFor="let location of element.locationsRef" class="d-flex align-items-center justify-content-between" mat-menu-item>
                                  <span class="d-flex align-items-center">
                                    <span class="mr--5">{{location.meta?.locationName}} -</span>
                                    <gmb-address 
                                      *ngIf="location.meta?.address || location.meta?.serviceArea"
                                      [address]="location.meta?.address"
                                      [serviceAreas]="location.meta?.serviceArea?.places?.placeInfos">
                                    </gmb-address>
                                  </span>
                                </button>
                              </ng-container>
                            </mat-menu>
                          </ng-container>
                          <ng-template #noAccounts>
                            <button mat-flat-button color="accent" class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                              {{element?.locationsRef.length}} locations <em class="fas fa-angle-down ml--10 mr--0"></em>
                            </button>
                          </ng-template>
                        </ng-container>
                        <span *ngIf="element?.toScheduleDelete" (click)="openSchedule(element)" style="background-color: #f2a32b; color: #fff; padding: 5px; margin:5px;" class="btn btn--badge btn--badge--sm txt--xs">Pending delete on {{getDate(element) | date:'shortDate'}}</span>
                        <p *ngIf="element?.missingLocations > 0" class="txt--red p--5">Error: {{element?.missingLocations}} of {{element?.planned}} posts were unsuccessful. 
                          <a href="https://mapssupport.atlassian.net/servicedesk/customer/portal/1" target="_blank" class="cursor--pointer txt--underline">Click here</a> to contact support.</p>
                      </div>

                      <read-more [text]="element.postInfo.summary" [maxLength]="300" [showToggleButton]="true"></read-more>

                  </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef><strong>{{post_type  === 'Pending' || post_type  === 'Drafts'? 'Created at' : 'Date Published'}}</strong></th>
              <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('date')">
                  <div class="cell__content">
                  <p>{{ adjustScheduleTime(element.postInfo.createTime || element.createdAt) |  date: 'MM/dd/yyyy h:mm a zzzz'}}</p>
                  </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="dateUpdate">
              <th mat-header-cell *matHeaderCellDef><strong>Last Updated</strong></th>
              <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('dateUpdate')">
                  <div class="cell__content">
                  <p>{{ element.updatedAt ? ( adjustScheduleTime(element.updatedAt) | date: 'MM/dd/yyyy h:mm a zzzz') : 
                                            ( adjustScheduleTime(element.createdAt) | date: 'MM/dd/yyyy h:mm a zzzz') }}</p>
                  </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Status</strong></th>
              <td mat-cell *matCellDef="let element">
                <div class="cell__content flex-column align-items-center justify-content-center" [ngClass]="{ 'alternate-theme' : element.status === 'DRAFT' }">
                  <ng-container *ngIf="!element.isScheduled && !element.isRecurring && !element.hasError">
                    <ng-container *ngIf="isPendingStatus(element.status); else normalStatus">
                      <button mat-flat-button [color]="'primary'"  [matTooltip]="getMessage(element.status)" class="btn--badge btn--badge--sm">
                        <em class="far fa-file-alt"></em> <span>{{getStatus(element.status)}}</span>
                      </button>
                    </ng-container>
                    <ng-template #normalStatus>
                      <button mat-flat-button [color]="'primary'" class="btn--badge btn--badge--sm" >
                        <em class="far fa-file-alt"></em> <span>{{ element.status | uppercase }}</span>
                      </button>
                    </ng-template>


                  </ng-container>

                  <ng-container *ngIf="element.isScheduled && element.scheduled && !element.hasError">
                    <button  mat-flat-button [matMenuTriggerFor]="scheduleMenu" color="primary"
                            class="btn--badge btn--badge--sm">
                      <em class="fal fa-calendar-alt"></em>
                      <span>SCHEDULED</span>
                    </button>
                    <mat-menu #scheduleMenu="matMenu">
                      <button class="d-flex align-items-center" mat-menu-item>
                        <span class="mr--5">{{ adjustScheduleTime(element.scheduled?.date) | date: 'MM/dd/yyyy' }} at {{ element.scheduled?.time | hours12 }}</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                  <ng-container *ngIf="element.isRecurring && element.recurring && !element.hasError">
                    <button  mat-flat-button [matMenuTriggerFor]="recurringMenu" color="primary"
                            class="btn--badge btn--badge--sm">
                      <em class="fal fa-calendar-alt"></em>
                      <span>RECURRING</span>
                    </button>
                    <mat-menu #recurringMenu="matMenu">
                      <span class="mr--5">Post will recur on:</span>
                      <button class="d-flex align-items-center" mat-menu-item>
                        <span class="mr--5"> {{ adjustScheduleTime(element.recurring?.nextDate) | date: 'EEEE, MM/dd/yyyy' }} at {{ getTime(element.recurring?.time)}}</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                  <button *ngIf="element.hasError" mat-flat-button (click)="openError(element)" color="warn"
                          class="btn--badge btn--badge--sm">
                    <em class="fas fa-exclamation-triangle"></em>
                    <span>ERROR</span>
                  </button>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="progress" >
              <th mat-header-cell *matHeaderCellDef class="txt--center"><strong>Progress</strong></th>
              <td mat-cell *matCellDef="let element" >
                  <div class="cell__content">
                    <ng-container *ngIf="isDisplayField(element.status)">
                      <div class="progress-label" style="padding: 0px 10px;">
                          <mat-progress-bar
                          color="primary"
                          [mode]="bulkPost ? 'determinate' : 'indeterminate'"
                          [value]="progressValue(element)">
                          </mat-progress-bar>
                          <ng-container *ngIf="element.status === pendingDeleteStatus; else elseTemplate">
                            <p class="progress-label__txt {{ ( (element.planned - element.posted)  / element.planned) < 0.6 ? 'txt--black' : 'txt--white' }}">
                              <span *ngIf="bulkPost" >{{ (element.planned - element.posted) }}/{{ element.planned }}</span>
                            </p>
                          </ng-container>
                          <ng-template #elseTemplate>
                            <p class="progress-label__txt {{ (element.posted / element.planned) < 0.6 ? 'txt--black' : 'txt--white' }}">
                              <span *ngIf="bulkPost" >{{ element.posted }}/{{ element.planned }}</span>
                            </p>
                          </ng-template>


                      </div>
                    </ng-container>
                  </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="views">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalViews" class="position-sort-views"><strong>Views</strong></th>
              <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('views')">
                  <div class="cell__content flex-column align-items-start justify-content-center align-items-center">

                      {{  (dashboardType=='BULK_POST' ?  getCountTotalViews(element?.locationsRef) : element.totalViews) | number:'1.0-0'}}
                  </div>
              </td>
            </ng-container>


            <ng-container matColumnDef="actions_post">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="totalAction" class="position-sort-actions"><strong>Actions</strong></th>
              <td mat-cell *matCellDef="let element" [style.width]="getSizeColumn('actions_post')">
                  <div class="cell__content flex-column align-items-start justify-content-center align-items-center">

                      {{ (dashboardType=='BULK_POST' ? getCountTotalActions(element?.locationsRef) : element.totalAction) | number:'1.0-0'}}
                  </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
              <td mat-cell *matCellDef="let element; index as i" class="txt--right" [style.width]="getSizeColumn('actions')">
                  <div class="cell__content">

                    <button mat-flat-button color="primary" class="btn btn-icon mr--10" (click)="edit(element)" *ngIf="(post_type !== 'Archive') && (!disableAction(element.status))">
                        <em class="fas fa-pen"></em>
                    </button>

                    <button mat-flat-button class="btn btn-icon mr--10 color_grey" *ngIf="(post_type !== 'Archive') && (disableAction(element.status))" [matTooltip]="'Posts that are in progress can not be edited'"
                    matTooltipClass="tooltip tooltip--white" >
                        <em class="fas fa-pen"></em>
                    </button>

                    
                  <span class="alternate-theme d-flex">
                      <!-- <button mat-flat-button color="warn" class="btn btn-icon mr--10" (click)="copy(element.id)">
                      <em class="fas fa-copy"></em>
                      </button> -->

                      <button mat-flat-button class="btn btn-icon" *ngIf="(post_type !== 'Archive') && (disableDeleteAction(element))" class="btn btn-icon mr--10 color_grey" [matTooltip]="'Posts that are in progress can not be deleted'"
                      matTooltipClass="tooltip tooltip--white">
                          <em class="far fa-trash-alt mr--5"></em>
                      </button>
                      <button mat-flat-button color="primary" class="btn btn-icon" [matMenuTriggerFor]="deleteOptions" *ngIf="(post_type !== 'Archive') && (!disableDeleteAction(element))" class="btn btn-icon mr--10">
                          <em class="far fa-trash-alt mr--5"></em> <em class="fal fa-angle-down"></em>
                      </button>
                      <mat-menu #deleteOptions="matMenu">
                          <button mat-button color="warn" (click)="delete(element.id, element.status)">Delete</button>
                          <button *ngIf="post_type  === 'Posted'" mat-button color="warn" (click)="openSchedule(element)">Scheduled Delete</button>
                      </mat-menu>
                  </span>
                  </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="no-hover"></tr>

        </table>

    </ng-container>
    <ng-template #emptyPosts>
      <div class="box bg--white">
        <div class="box--margin d-flex align-items-center justify-content-center">
          <div class="no-posts-message">
            <h2 class="txt--capitalize bulk-post-void"><strong>No posts added yet</strong></h2>
            <h6 class="bulk-post-info" *ngIf="dashboardType === 'LOCAL_POST'">
              <strong>If you've scheduled this location as part of a Bulk Post, please go to </strong><a class="bulk-post-link" href="https://app.maplabs.com/local-posts">Bulk Post</a>
            </h6>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="mat-row mat-row--padding-md bg--white d-flex justify-content-start align-items-center" *ngIf="postsExist">
        <app-paginator [pagination]="pagination" *ngIf="pagination && !progressPost" (reload)="handleReload($event)"></app-paginator>
    </div>
</ng-container>
