// dep
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { AngularFireStorageReference } from "@angular/fire/storage";
import { BehaviorSubject } from 'rxjs';

// app
import { TypeImages } from 'src/app/constants/types';
import { StorageImageService } from '../../services/storage-image.service';
import { ModalService } from 'src/app/services/modal.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ImageRequirement } from 'src/app/constants/google-media';

// Media after being uploaded to Firebase Storage
export type UploadedMedia = { url: string, 
                              mediaFormat: TypeImages, 
                              preview: string, 
                              fileName: string }

@Component({
  selector: 'app-form-input-upload',
  templateUrl: './form-input-upload.component.html',
  styleUrls:  ['./form-input-upload.component.scss']
})
export class FormInputUploadComponent extends BaseComponent implements OnChanges, OnInit {
  /**
   * - `media` - upload a photo or a video to a Location Media library
   * - `post`  - upload a photo to a Post
   */
  @Input() actionText : 'media' | 'post' = 'post';
  @Input() urlInput: any = null;
  @Input() requirements: ImageRequirement
  @Input() noLoader: boolean;
  @Input() imageId = '';
  @Output() public url = new EventEmitter<null | UploadedMedia>();
  @Output() public ref = new EventEmitter<AngularFireStorageReference>();
  @Output() public percentUpload = new EventEmitter<number>();
  @Input() category: TypeImages = 'PHOTO';
  @Output() public changedImg = new EventEmitter<void>();
  @Input() isMultiple = false;

  @ViewChild("fileInput")
  InputFile: ElementRef;

  public showPreview$ = new BehaviorSubject(false)

  public imageUrl: string;

  public fileUrl: string;
  public percent: number;
  public isUploading = false;
  public showLoader: boolean;

  public hasPhoto = true;

  constructor(private _storageS: StorageImageService, 
              private _changeDetector: ChangeDetectorRef,
              private _modalS: ModalService) {
    super();
  }

  ngOnInit() : void {
    if (this.urlInput) {
      this.showPreview$.next(true);
      this.imageUrl = this.urlInput;
    }
    this.showLoader = (this.noLoader || false);
  }

  ngOnChanges({ urlInput }: SimpleChanges): void {
    if (!urlInput.currentValue) {
        this.showPreview$.next(false);
        this.imageUrl = null;
    }
  }

  public fileChanged(target: EventTarget) : void {
    this._storageS.setUrl(null);
    // FIXME multiple subscriptions
    this._subscribeSafe(this._storageS.url$,
     url => {
        if (url) {
          if (url.error) {
            this.reset();
          } else if (url.preview) {
            this.url.emit({url: url.url, mediaFormat: 'VIDEO', preview: url.preview, fileName: null});
            this.imageUrl = url.preview;
            this.category = 'VIDEO';
            this.showPreview$.next(true);
          } else {
            this._storageS.setImageUrl(url.url)
            this.url.emit({url: url.url, mediaFormat: 'PHOTO', preview: null, fileName: url.fileName});
            this._subscribeSafe(this._storageS.getImageUrl(), url => {
                this.imageUrl = url;
                this.hasPhoto = !url.includes('/assets/images/camera.svg');
              });

            this.category = 'PHOTO';
            this.showPreview$.next(true);
          }
        } else {
          this.url.emit(null);
          this.imageUrl = null
          this.category = 'PHOTO';
          this.showPreview$.next(false);
        }
        this.isUploading = false;
      },(e)=>{
        console.error(e);
        this._modalS.openErrorModal(
          'Upload Error',
          "Error uploading the image, try again"
        )
        this.isUploading = false;
    });

    this.isUploading = true;
    this.changedImg.emit();
    this._storageS.fileChanged(target as HTMLInputElement, true, this.requirements);
  }


  reset() : void {
    this.showPreview$.next(false);
    this.imageUrl = null;
    this._storageS.reset();
    this.InputFile.nativeElement.value = "";
    this._changeDetector.markForCheck();
  }

  ngAfterViewChecked() : void {
    this._changeDetector.detectChanges();
  }

}
