
<div class="input-field input-field--image input-field--image--fluid bg--gray">
  <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--sticky" *ngIf="isUploading && !showLoader">
    <mat-spinner></mat-spinner>
  </div>
  
  <label for="fileInput{{imageId}}" class="" [class.activate-hover]="imageUrl">
    <img class="file-preview" alt="image post" [ngClass]="hasPhoto ? '' : 'new-image'" [src]="imageUrl" *ngIf="showPreview$ | async">
    <div class="preview-text txt--center">
      <img src="/assets/images/camera.svg" alt="Upload photos here." class="img--xs mb--10">
      <span class="d-block txt--sm txt--lightgray" *ngIf="actionText.toLowerCase() == 'media'"> 
        Upload {{ category === 'PHOTO' ? 'an image' : 'a video'}} to your media library 
      </span>
      <span class="d-block txt--sm txt--lightgray" *ngIf="actionText.toLowerCase() == 'post'"> 
        Make your post standout <span class="d-block mt--5">with a photo</span>
      </span>
    </div>
  </label>

  <input #fileInput 
         id="fileInput{{imageId}}" 
         type="file" 
         [multiple]="isMultiple" 
         (change)="fileChanged($event.target)" 
         name="fileMedia" 
         hidden 
         [accept]="requirements.accepts"/>

  <input type="text" [(ngModel)]="fileUrl" name="fileUrl" hidden/>
</div>

<mat-progress-bar mode="determinate" [value]="percent"
                  *ngIf="percent && percent !=100"></mat-progress-bar>
