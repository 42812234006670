import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-delete-media',
  templateUrl: './modal-delete-media.component.html',
  styleUrls: ['./modal-delete-media.component.scss']
})
export class ModalDeleteMediaComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialog: MatDialogRef<ModalDeleteMediaComponent>
  ) {
    this.data = data.data;
  }

}
