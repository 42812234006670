<div class="dialog__header" *ngIf="!bulk">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Add photos</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <app-photos-location #photoComponent 
                       [bulk]="bulk" 
                       [accountId]="accountId"
                       [locationId]="locationId" 
                       [locationType]="locationType"
                       [showOnlyMedia]="showOnlyMedia"
                       [error]="error"
                       [error_details]="error_details">
  </app-photos-location>
</div>
<div mat-dialog-actions class="dialog__footer alternate-theme" *ngIf="!bulk">
    <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">
      Cancel
    </button>
</div>
