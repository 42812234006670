// dep
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
// import {SwiperComponent} from "ngx-swiper-wrapper";

// app
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageImageService } from 'src/app//services/storage-image.service';
import { IMediaUrl } from 'src/app/constants/media-url';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
    selector: 'app-form-input-upload-multiple',
    templateUrl: './form-input-upload-multiple.component.html',
    styleUrls: ['./form-input-upload-multiple.component.scss']
})
export class FormInputUploadMultipleComponent extends BaseComponent implements OnInit, OnDestroy {
    /*
    - upload many files from scratch
    - load previous files on edit and allow upload and edit
    - delete single file but preserve the rest
    - display current files with preview and button to delete
    - differentiate photos from videos
    - save info about file and type on same array and communicate through a subject
    - pay attention to inputs/outputs
    - display errors
    */

    @Input() urlInput: string;
    @Input() requirements: any;
    @Input() multiple = true;
    @Input() maxNumberPhotos = 10
    // @Input() category: TypeImages = 'PHOTO';
    @Input() imageId = '';
    @Input() actionText = 'post' ;

    // @Output() url = new EventEmitter();
    // @Output() percentUpload = new EventEmitter();
    @Output() ref = new EventEmitter();

    // @ViewChild('gallery', { static: false }) gallery: SwiperComponent;
    
    @ViewChild('fileInput') fileInput: ElementRef;

    public isUploading = false;
    public index = 0;
    public mediaUrlArray: IMediaUrl[] = [];
    public swiperConfig: any = {
        slidesPerView: 4,
        initialSlide: 0,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        simulateTouch: true,
        slideToClickedSlide: true
    };
    public imageTxt = 'Upload images';

    constructor(
        private _storageImgService: StorageImageService,
        private _snackService: SnackbarService
    ) { 
        super();
    }

    ngOnInit(): void {
        this.imageTxt = this.multiple ? 'Upload images' : 'Upload an image';
        this._subscribeSafe(this._storageImgService.fileDeleted$, () => {
            this._resetFileInput();
        });
        // the following subscription ensures that if there are any failed files, they get removed from the current file array
        // so they can be validated again if uploaded
        this._subscribeSafe(this._storageImgService.failedMediaArray$, (failedFiles: string[]) => {
            if (failedFiles.length) {
                const fileList: File[] = Array.from(this.fileInput.nativeElement.files)
                const filteredFiles = fileList.filter(file => !failedFiles.includes(file.name));
                const dataTransfer = new DataTransfer();

                filteredFiles.forEach(file => dataTransfer.items.add(file));

                this.fileInput.nativeElement.files = dataTransfer.files || null;
            }
        })
    }

    private _resetFileInput() {
        this.fileInput.nativeElement.value = '';
    }

    // target should be the type returned by the event.target of a file input
    filesChanged(target: EventTarget): void {
        console.log('files changed', this.fileInput.nativeElement.files);
        
        const files = (target as HTMLInputElement).files;
        // const inputElement = target as HTMLInputElement;

        if (files instanceof FileList && files.length > 0) {
            if (files.length > this.maxNumberPhotos) {
                this._snackService.openWarning(`You can only upload up to ${this.maxNumberPhotos} images.`);
                return;
            }
            this._storageImgService.fileChanged(target as HTMLInputElement, false, this.requirements, null, this.maxNumberPhotos);
        }
    }

}