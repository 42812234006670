import {Component, Inject, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CATEGORIES_DATA, GoogleMediaItem, ModalCategories} from '../../constants/google-media';
import AccountReport from 'src/app/services/account-report';
import { PhotosLocationComponent } from '../dashboard-location/photos-location/photos-location.component';


@Component({
  selector: 'app-modal-photos',
  templateUrl: './modal-photos.component.html',
  styleUrls: ['./modal-photos.component.scss']
})
export class ModalPhotosComponent {
  imageUrl: string[] = [];
  imageSelectedUrl: string = null;
  categories: any[];
  fileUrl: string;
  description: string;
  listFiles = new ModalCategories();
  category: { name: string };
  isUploading: boolean;
  error: any;
  error_details: any;
  public percentUpload: number;

  @Input() primaryCategory: any;
  selectedCategory: {name: string, value: string} =  null;
  @Input() accountId: string = null;
  @Input() locationId: string = null;
  @ViewChild('photoComponent') photoComponent: PhotosLocationComponent;
  @Input() bulk = false;
  @Input() locationType = '';
  @Input() showOnlyMedia = false;
  @Input() accounts: AccountReport[] = null;
  @Output() validResult = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalPhotosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.categories = CATEGORIES_DATA;
    this.selectedCategory = this.categories[0];
    this.category = {name: 'CATEGORY_UNSPECIFIED'};
    if (data) {
      this.accountId = this.data.accountId;
      this.locationId = this.data.locationId;
      this.locationType = this.data.typeLocation;
      this.showOnlyMedia = this.data.showOnlyMedia;
      this.error = this.data.error
      this.error_details = this.data.error_details
    }
  }

  getResult(): Partial<GoogleMediaItem>[]  {
    const r = this.photoComponent.getResult();

    this.validResult.emit(!!r);
    return r; 
  }

}
