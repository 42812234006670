<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>

<app-modal-stepper
  [title]="stepTitle"
  linear 
  [finalAction]="'Finish'"
  [scan_action]="false"
  [step]="step"
  [hasDraft]="true"
  [isEdit]="isEdit"
  [isDraft]="isDraft"
  [drawer]="drawer"
  [stepTitle]="!isEdit ? ['Create Post'] : ['Edit Post']"
  [disabledStep]="disabledNextStep"
  [cancelText]="cancelText"
  [cancelButton]="'No, keep editing'"
  [closeLabel]="'Cancel'"
  [isSlider]="true"
  [isSliderOpened]="isSliderOpened"
  [closeDrawerAfterComplete]="false"
  [dataIsBeingVerified]="dataIsBeingVerified"
  [verifiedDataLabel]="'Post'"
  (onStepChange)="onStepChange($event)"
  (backStep)="backStep($event)"
  (completed)="completeSteeper()"
  (postDraftEvent)="completeSteeper(true)"
  (destroyData)="ngOnDestroy()"
>
  <cdk-step [completed]="completeStep1">
    <div *ngIf="!isBulk" class="single-location-data">
      <div class="box box--padding-xxxs box--rounded bg--lightblue d-flex justify-content-between align-items-center">
        <h6 class="fw--medium txt--blue m--0">{{location[0]?.accountName}}</h6>
      </div>

      <div class="dialog-row pr--15 pl--15 mt--10 d-flex justify-content-between align-items-center single-location-data-location">
        <div class="company">
          <div class="table__icon table__icon--lg">
            <svg-icon src="/assets/images/icons/pin.svg"></svg-icon>
          </div>
          <div class="company__info">
            <h3 class="company__title m--0"><strong>{{location[0]?.locations[0]?.locationName}}</strong></h3>
            <gmb-address [address]="location[0]?.locations[0]?.address" [serviceAreas]="location[0]?.locations[0]?.serviceArea?.places?.placeInfos"></gmb-address>
          </div>
        </div>
      </div>
    </div>

    <app-toggle-locations-in-account
      *ngIf="isBulk"
      class="locations-no-padding"
      [isSlider]="true"
      [hasGoToLocation]="true"
      [txtInfoNotification]="'Select at least 2 locations to create a bulk post. To create a post for a single location, go to Post Management in the Location view.'"
      [isSliderOpened]="isSliderOpened"
      [reportEdit]="isEdit ? postToEdit?.accounts : !isBulk ? location : null"
      (selected)="selectedLocations($event)"
    ></app-toggle-locations-in-account>
  </cdk-step>

  <cdk-step [completed]="completeStep2">
    <form [formGroup]="form">

      <section #postTypeSection class="bg--off--white p--20 mb--20">
        <div class="dialog-row p--0">
          <mat-label class="d-block mb--20 txt--14 fw--700">Post Type *</mat-label>
          <mat-radio-group formControlName="postType" (change)="addValidationsStepTwo()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
            <mat-radio-button [value]="'STANDARD'" class="txt--14 p--0 pr--50">Update</mat-radio-button>
            <mat-radio-button [value]="'EVENT'" class="txt--14 p--0 pr--50">Event</mat-radio-button>
            <mat-radio-button [value]="'OFFER'" class="txt--14 p--0 pr--50">Offer</mat-radio-button>
          </mat-radio-group>
          <mat-error class="mt--5" *ngIf="postType?.errors && form?.touched">The post type is required</mat-error>
        </div>
      </section>

      <section class="bg--off--white p--20 pt--0 border-bottom">
        <p class="mb--10 txt--14 fw--700">
          Frequency *
          <app-info-tooltip [tooltip]="'For any recurring posts, once the post has been scheduled or published, you will not be able to change the schedule settings and have to recreate the bulk post by cloning or from scratch.'"></app-info-tooltip>
        </p>
        <mat-select formControlName="cadence" class="input-group__field p--10 col-12 col-lg-4 bg--white" placeholder="Select Frequency" (selectionChange)="addValidationsStepTwo()" 
        [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
          <mat-option [value]="'INSTANT'">Does not repeat</mat-option>
          <mat-option [value]="'DAILY'">Daily</mat-option>
          <mat-option [value]="'WEEKLY'">Weekly</mat-option>
          <mat-option [value]="'MONTHLY'">Monthly</mat-option>
        </mat-select>
        <mat-error class="mt--5 d--block" *ngIf="cadence?.errors && form?.touched">Frequency is required</mat-error>
      </section>


      <section #postFrequencySection class="bg--off--white p--20" *ngIf="cadence?.value && step === 1">
        <section>
          <div class="dialog-row mb--20 pt--0 pb--0">
            <p class="d-block mb--20 txt--lg fw--700">{{postTypeLabel | titlecase}} Details</p>
          </div>

          <!-- DETAILS -->
          <section class="mb--20"> 
            <div class="col-12 p--0">
              <div class="dialog-row p-tb--0" *ngIf="postType?.value != 'STANDARD'">
                <p class="mb--10 txt--14 fw--700">Title *</p>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <input matInput class="input-group__field" maxLength="58" formControlName="title" placeholder="Title" required/>
                  <mat-hint align="end">{{title?.value?.length ? title?.value?.length : 0}} / 58</mat-hint>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="title?.errors && form?.touched">Title is required</mat-error>
              </div>
              
              <div class="dialog-row p-tb--0 mt--10">
                <p class="mb--10 txt--14 fw--700">Description *</p>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label w--100">
                  <textarea matInput class="input-group__field" formControlName="summary" maxlength="1500" placeholder="Add a description" [rows]="postType == 'STANDARD' ? 10 : 6"></textarea>
                  <mat-hint align="end">{{summary?.value?.length || 0 }} / 1500</mat-hint>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="summary?.errors && form?.touched">Description is required</mat-error>
              </div>
            </div>
            <div>
              <p class="mb--10 txt--14 fw--700 mt--10">
                Media (Optional)
              </p>
              <div class="row d-flex pr--20 pl--12">
                <!-- start input -->
                <app-form-input-upload-multiple
                  [multiple]="false"
                  [maxNumberPhotos]="1"
                  [urlInput]="imageSelectedUrl"
                  [requirements]="imageRequirements"
                  (ref)="handleRef($event)"
                  >
                </app-form-input-upload-multiple>
                <!-- end input -->
                <!-- start swipper -->
                <div class="col-9 pl--30">
                  <ng-container *ngIf="mediaUrlArray?.length >= 1">
                    <div class="w--100">
                      <swiper [config]="swiperConfig" class="carousel carousel--thumbs preview-photos">
                        <div class="thumb-wrapper thumb-wrapper-4" *ngFor="let file of mediaUrlArray">
                          <!-- delete icon on top right for every thumbnail -->
                          <button
                            (click)="openDelete(file)"
                            matTooltip="Delete"
                            matTooltipClass="tooltip tooltip--red"
                            matTooltipPosition="above"
                            class="btn btn-delete btn-delete--gallery ">
                            <i class="fas fa-trash-alt delete-file"></i>
                          </button>
                          <img [src]="file.category === 'VIDEO' ? file.preview : file.url" [ngClass]="file.fileName ? '' : 'new-image'" [alt]="file.url">
                        </div>
                      </swiper>
                    </div>
                  </ng-container>
                </div>
                <!-- end swipper -->
              </div>
              <div class="mt--10">
                <ul class="list-style-disc pl--12">
                  <li class="m--0 txt--dark--gray">Only PNG, JPG formats are allowed.</li>
                  <li class="m--0 txt--dark--gray">File size must be between 10KB and 5MB.</li>
                  <li class="m--0 txt--dark--gray">Image dimensions must be between 480x270px and 2120x1192px.</li>
                  <li class="m--0 txt--dark--gray">For best quality, upload images with dimensions of at least 720x540px in a 4:3 aspect ratio.</li>
                </ul>
              </div>
            </div>
          </section>
          
          <!-- BUTTON -->
          <div class="bg--off--white mt--20">
            <section *ngIf="postType?.value != 'OFFER'">
              <p class="mb--10 txt--14 fw--700 col-12 p--0">
                Add button (Optional)
                <app-info-tooltip [tooltip]="'Add a call to action button to your post.'"></app-info-tooltip>
              </p>
              <mat-select formControlName="actionType" class="input-group__field w--25 p--10 w--100" (selectionChange)="getActionLinks()">
                <mat-option *ngFor="let option of actionOptions;" [value]="option?.value"> {{option?.displayName}} </mat-option>
              </mat-select>
    
              <div class="mt--10" *ngIf="actionType && actionType?.value !== 'NONE' && actionType?.value !== 'CALL'">
                <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block p--0 btn-action">
                  <input matInput class="input-group__field pb--10" formControlName="actionUrl" [placeholder]="isLoading ? '' : actionsUrl?.length > 0 ? 'Insert button link or select placeholder from below' : 'Insert button link'"/>
                  <button mat-icon-button color="primary" class="btn btn-icon btn--rounded ml--10 mb--20 icon-delete-action" (click)="clearActionUrl()">X</button>
                </mat-form-field>
                <mat-error class="mt--5" *ngIf="actionUrl?.errors && form?.touched">Link is required</mat-error>
    
                <section *ngIf="actionsUrl?.length > 0" class="input-group__field bg--white mt--10">
                  <button mat-flat-button color="primary" style="margin: 5px;" *ngFor="let url of actionsUrl" (click)="addActionUrl(url)">{{url.value}}</button>
                  <p style="margin-top: 10px; color: #8c91a2;"><em class="fas fa-info-circle mr--5"></em>Select an above placeholder to insert data from your business profile into your button link.</p>
                </section>
              </div>
    
              <div class="mt--10" *ngIf="actionType?.value === 'CALL'">
                <section class="input-group__field bg--white mt--10">
                  <p class="m--0" style="color: #8c91a2;">
                    <em class="fas fa-info-circle mr--5"></em>The primary phone number for each location will be used for customer to call.
                  </p>
                </section>
              </div>
            </section>
          </div>

          <section *ngIf="postType?.value == 'OFFER'" class="additional-info mb--20">
            <p class="d-block mb--20 txt--lg fw--700">
              Additional Info (Optional)
              <app-info-tooltip [tooltip]="'Add any additional details like coupon code or terms to your offer.'"></app-info-tooltip>
            </p>

  
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <input matInput class="input-group__field pb--10" formControlName="couponCode" placeholder="Coupon code name"/>
            </mat-form-field>
  
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <input matInput class="input-group__field pb--10" formControlName="linkToRedeem" placeholder="Link to Redeem Offer"/>
            </mat-form-field>
  
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <input matInput class="input-group__field" formControlName="termsAndCondition" placeholder="Terms and Conditions"/>
            </mat-form-field>
  
          </section>

          <!-- SHEDULE -->
          <div class="bg--off--white mt--20">
            <section *ngIf="postType?.value != 'STANDARD'">
              <mat-divider class="mt--20 mb--20"></mat-divider>
              <p class="d-block mb--5 txt--lg fw--700">{{postTypeLabel | titlecase}} Schedule</p>
              <p class="mb--10txt--14">Schedule is always set in local business time</p>
            </section>

            <section *ngIf="cadence?.value == 'DAILY' && postType?.value != 'STANDARD'">
              <ng-container class="w--auto d--inl-block">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  {{postTypeLabel | titlecase}} Days *
                  <app-info-tooltip [tooltip]="'Your ' + (getLowercase(postType?.value)) + ' starts and ends in single day. You can select multiple days in a week.'"></app-info-tooltip>
                </p>

                <p class="txt--14 m--0">Select days of week:</p>
                <div class="d-flex m-auto mt--10 mb--10">
                  <span class="frecuency-week-selector ml--0" (click)="selectDay(0)" [ngClass]="{'activate': publishDay?.value?.includes(0)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SU</span>
                  <span class="frecuency-week-selector" (click)="selectDay(1)" [ngClass]="{'activate': publishDay?.value?.includes(1)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">MO</span>
                  <span class="frecuency-week-selector" (click)="selectDay(2)" [ngClass]="{'activate': publishDay?.value?.includes(2)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TU</span>
                  <span class="frecuency-week-selector" (click)="selectDay(3)" [ngClass]="{'activate': publishDay?.value?.includes(3)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">WE</span>
                  <span class="frecuency-week-selector" (click)="selectDay(4)" [ngClass]="{'activate': publishDay?.value?.includes(4)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TH</span>
                  <span class="frecuency-week-selector" (click)="selectDay(5)" [ngClass]="{'activate': publishDay?.value?.includes(5)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">FR</span>
                  <span class="frecuency-week-selector" (click)="selectDay(6)" [ngClass]="{'activate': publishDay?.value?.includes(6)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SA</span>
                </div>
                <p *ngIf="publishDay?.value?.length > 0" class="m--0 txt--dark--gray">{{publishDay.value.length}} days have been selected</p>
                <mat-error class="mt--5" *ngIf="publishDay?.errors && form?.touched">Day of week is required</mat-error>
              </ng-container>
            <!-- </section> -->

              <ng-container *ngIf="postType?.value != 'STANDARD'" class="mb--20">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  {{postType?.value | titlecase}} Start and End Times
                  <app-info-tooltip *ngIf="cadence?.value === 'DAILY'" [tooltip]="'Google will display the Start and End Times for users when it is a single-day event or offer, with default values of 12:00 AM and 11:59 PM.'"></app-info-tooltip>
                </p>
                
                <div class="col-12 row">
                  <section class="col-12 col-lg-3 p--0 mr--20">
                    <mat-form-field class="input-group input-group--override input-group--override-label">
                      <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                        aria-label="Start time"
                        [placeholder]="eventTimeStart?.value ? 'Time' : 'Time (optional)'"
                        matInput
                        formControlName="eventTimeStart"
                        [matAutocomplete]="auto"
                        (keydown)="validateInput($event)"
                        (blur)="formatTime($event, 'eventTimeStart')"
                        (input)="convertToUppercase($event, 'eventTimeStart')"
                        [readonly]="isEdit && !isDraft"
                        [disabled]="isEdit && !isDraft">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                          {{ hour }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="eventTimeStart?.errors && form?.touched">Start time is required</mat-error>
                  </section>
      
                  <section class="col-12 col-lg-3 p--0">
                    <mat-form-field class="input-group input-group--override input-group--override-label">
                      <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                        aria-label="End time"
                        [placeholder]="eventTimeEnd?.errors ? 'Time' : 'Time (optional)'"
                        matInput
                        formControlName="eventTimeEnd"
                        [matAutocomplete]="auto"
                        (keydown)="validateInput($event)"
                        (blur)="formatTime($event, 'eventTimeEnd')"
                        (input)="convertToUppercase($event, 'eventTimeEnd')"
                        [readonly]="isEdit && !isDraft"
                        [disabled]="isEdit && !isDraft">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">{{ hour }}</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="eventTimeEnd?.errors && form?.touched">{{getError(eventTimeEnd, 'End time', 'start time')}}</mat-error>
                  </section>
                </div>
              </ng-container>
            </section>

            <section *ngIf="postType?.value != 'STANDARD' && cadence?.value !== 'DAILY'">
              <ng-container id="startDateSchedule">
                <p class="mb--10 mt--20 txt--14 fw--700">
                  {{postType?.value | titlecase}} Start Date * 
                  <app-info-tooltip [tooltip]="(postType?.value | titlecase) + ' Start and End Time are optional, but if you enter a start time, you must enter an end time.'"></app-info-tooltip>
                </p>

                <div class="row col-12">
                  <section *ngIf="cadence?.value === 'INSTANT'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                      <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                      [min]="getMinDate()"
                      placeholder="Start Date"
                      formControlName="instantEventDatesStart"
                      [matDatepicker]="instantEventDatesStartCalendar"
                      (click)="instantEventDatesStartCalendar.open()"
                      [readonly]="isEdit && !isDraft"
                      [disabled]="isEdit && !isDraft">
                      <mat-datepicker #instantEventDatesStartCalendar></mat-datepicker>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="form?.controls?.instantEventDatesStart?.errors && form?.touched">{{getError(instantEventDatesStart, 'Start day', 'end day')}}</mat-error>
                  </section>

                  <section *ngIf="cadence?.value === 'WEEKLY'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="eventDatesStartWeekly"
                      placeholder="Start day"
                      [disabled]="isEdit && !isDraft">
                      <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="eventDatesStartWeekly?.errors && form?.touched">Start day is required</mat-error>
                  </section>

                  <section *ngIf="cadence?.value === 'MONTHLY'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="eventDatesStartMonthly"
                      placeholder="Start day"
                      [disabled]="isEdit && !isDraft">
                      <mat-option *ngFor="let day of daysList;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="eventDatesStartMonthly?.errors && form?.touched">Start day is required</mat-error>
                  </section>
      
                  <section class="col-12 col-lg-3 p--0">
                    <mat-form-field class="input-group input-group--override input-group--override-label">
                      <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                        aria-label="Start time"
                        matInput
                        [placeholder]="eventTimeStart?.value ? 'Time' : 'Time (optional)'"
                        formControlName="eventTimeStart"
                        [matAutocomplete]="auto"
                        (keydown)="validateInput($event)"
                        (blur)="formatTime($event, 'eventTimeStart')"
                        (input)="convertToUppercase($event, 'eventTimeStart')"
                        [readonly]="isEdit && !isDraft"
                        [disabled]="isEdit && !isDraft">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                          {{ hour }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="eventTimeStart?.errors && form?.touched">Start time is required</mat-error>
                  </section>
                </div>
              </ng-container>

              <ng-container id="endDateSchedule">
                <p class="mb--10 mt--10 txt--14 fw--700">{{postType?.value | titlecase}} End Date *</p>

                <div class="row col-12">
                  <section *ngIf="cadence?.value === 'INSTANT'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                      <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                      [min]="getMinDate()"
                      placeholder="End Date"
                      formControlName="instantEventDatesEnd"
                      [matDatepicker]="instantEventDatesEndCalendar"
                      (click)="instantEventDatesEndCalendar.open()"
                      [readonly]="isEdit && !isDraft"
                      [disabled]="isEdit && !isDraft">
                      <mat-datepicker #instantEventDatesEndCalendar></mat-datepicker>
                      <mat-error class="mt--5" *ngIf="instantEventDatesEnd?.errors && form?.touched">{{getError(instantEventDatesEnd, 'End day', 'start day')}}</mat-error>
                    </mat-form-field>
                  </section>

                  <section *ngIf="cadence?.value === 'WEEKLY'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="eventDatesEndWeekly"
                      placeholder="End Date"
                      [disabled]="isEdit && !isDraft">
                      <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="eventDatesEndWeekly?.errors && form?.touched">End day is required</mat-error>
                  </section>

                  <section *ngIf="cadence?.value === 'MONTHLY'" class="col-12 col-lg-3 p--0 mr--20">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="eventDatesEndMonthly"
                      placeholder="Start day"
                      [disabled]="isEdit && !isDraft">
                      <mat-option *ngFor="let day of daysList;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="eventDatesStartMonthly?.errors && form?.touched">Start day is required</mat-error>
                  </section>
      
                  <section class="col-12 col-lg-3 p--0">
                    <mat-form-field class="input-group input-group--override input-group--override-label">
                      <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                        aria-label="End time"
                        matInput
                        [placeholder]="eventTimeEnd?.errors ? 'Time' : 'Time (optional)'"
                        formControlName="eventTimeEnd"
                        [matAutocomplete]="auto"
                        (keydown)="validateInput($event)"
                        (blur)="formatTime($event, 'eventTimeEnd')"
                        (input)="convertToUppercase($event, 'eventTimeEnd')"
                        [readonly]="isEdit && !isDraft"
                        [disabled]="isEdit && !isDraft">
                      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">{{ hour }}</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-error class="position-error-label" *ngIf="eventTimeEnd?.errors && form?.touched">{{getError(eventTimeEnd, 'End time', 'start time')}}</mat-error>
                  </section>
                </div>
              </ng-container>
            </section>
          </div>
        </section>
      </section>

    </form>
  </cdk-step>

  <cdk-step [completed]="completeStep3">
    <div class="dialog-row mb--20 p--20 pt--0 pb--0" *ngIf="step === 2">
      
      <form [formGroup]="form">
        <section *ngIf="cadence?.value === 'INSTANT'">
          <!-- PUBLISH ON -->
          <ng-container  class="w--auto d--inl-block">
            <p class="mb--10 txt--14 fw--700">
              Publish On (Optional)
              <app-info-tooltip [tooltip]="'Your post will publish immediately, or you can schedule it to post at a later date.'"></app-info-tooltip>
            </p>

            <div class="row col-12">
              <section class="col-12 col-lg-3 p--0 mr--20">
                <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                  <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                    [min]="getMinDate()"
                    formControlName="postStartDate" 
                    placeholder="Enter a Date"
                    [matDatepicker]="myDatepickerStart" 
                    (click)="myDatepickerStart.open()"
                    (dateChange)="addFormFieldsStepThree()"
                    [readonly]="isEdit && !isDraft"
                    [disabled]="isEdit && !isDraft">
                  <mat-datepicker #myDatepickerStart ></mat-datepicker>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="postStartDate?.errors && form?.touched">{{getError(postStartDate, 'Publish date', 'delete date')}}</mat-error>
              </section>

              <section class="col-12 col-lg-3 p--0">
                <mat-form-field class="input-group input-group--override input-group--override-label">
                  <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                    aria-label="Start time"
                    matInput
                    [placeholder]="publishTime?.errors ? 'Time' : 'Time (optional)'"
                    formControlName="publishTime"
                    [matAutocomplete]="auto"
                    (keydown)="validateInput($event)"
                    (blur)="formatTime($event, 'publishTime')"
                    (input)="convertToUppercase($event, 'publishTime')"
                    [readonly]="isEdit && !isDraft"
                    [disabled]="isEdit && !isDraft">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">{{ hour }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="publishTime?.errors && form?.touched">Time is required</mat-error>
              </section>
            </div>
          </ng-container>

          <!-- DELETE ON -->
          <ng-container class="w--auto d--inl-block">
            <p class="mb--10 txt--14 fw--700">
              Delete Post On (Optional)
              <app-info-tooltip [tooltip]="'Schedule your post to be deleted on a specific date and time. If no time is specified it will be deleted at the end of the day (11:59 PM).'"></app-info-tooltip>
            </p>

            <div class="row col-12">
              <section class="col-12 col-lg-3 p--0 mr--20">
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                  [min]="getMinDate()"
                  formControlName="deleteDate"
                  [matDatepicker]="myDatepickerEnd"
                  placeholder="Enter a Date"
                  (click)="myDatepickerEnd.open()" 
                  [readonly]="isEdit && !isDraft"
                  [disabled]="isEdit && !isDraft"
                  matTooltipPosition="above"
                  [matTooltip]="getDisabledFieldTooltip()">
                  <mat-datepicker #myDatepickerEnd></mat-datepicker>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="deleteDate?.errors && form?.touched">{{getError(deleteDate, 'Delete day', 'publish day')}}</mat-error>
              </section>

              <section class="col-12 col-lg-3 p--0">
                <mat-form-field class="input-group input-group--override input-group--override-label">
                  <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                    aria-label="End time"
                    matInput
                    [placeholder]="deleteTime?.errors ? 'Time' : 'Time (optional)'"
                    formControlName="deleteTime"
                    [matAutocomplete]="auto"
                    (keydown)="validateInput($event)"
                    (blur)="formatTime($event, 'deleteTime')"
                    (input)="convertToUppercase($event, 'deleteTime')"
                    [readonly]="isEdit && !isDraft"
                    [disabled]="isEdit && !isDraft">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">{{ hour }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="deleteTime?.errors && form?.touched">{{getError(deleteTime, 'Delete time', 'publish time')}}</mat-error>
              </section>
            </div>
          </ng-container>
        </section>

        <section *ngIf="cadence?.value !== 'INSTANT'">
          <div class="alert alert-warning-dark mb--20" *ngIf="isEdit && !isDraft">
            <span class="icon-info-message col-2 vertical--align--top">
              <em class="fas fa-info"></em>
            </span>
            <p class="col-10 display-in-bl">The publishing schedule cannot be changed for this post series since it has already been scheduled or published. You'll need to recreate the post by either cloning it or starting from scratch.</p>
          </div>

          <p class="mb--10 txt--14 fw--700">
            Publishing Schedule
            <app-info-tooltip [tooltip]="'To keep your posts up to date and relevant, it’s ideal to publish your post at the same time as your event or offer.\n\nWhen no event/offer time is set, post will be published from 12:00 am to 11:59 pm by default.'"></app-info-tooltip>
          </p>
          <mat-checkbox *ngIf="postType?.value !== 'STANDARD'" class="txt--14" formControlName="matchScheduleDate" (change)="addFormFieldsStepThree()" [disabled]="isEdit && !isDraft">
            Use same schedule as the {{getLowercase(postType?.value)}}
          </mat-checkbox>

          <section *ngIf="cadence?.value !== 'INSTANT'" class="publishing-schedule-text">
            <p *ngIf="matchScheduleDate?.value && postType?.value !== 'STANDARD'" class="m--0" [innerHTML]="getPublishingSchedule"></p>

            <div *ngIf="postType?.value === 'STANDARD' || !matchScheduleDate?.value">
              <div class="alert alert-notification-dark mb--20" *ngIf="postType?.value !== 'STANDARD' && !isEdit">
                <span class="icon-info-message col-2 vertical--align--top">
                  <em class="fas fa-info"></em>
                </span>
                <ul class="col-10 display-in-bl list-style-disc">
                  <li *ngIf="cadence?.value !== 'DAILY'" class="m--0">For event and offer posts, each post will be unpublished when the event ends to ensure no expired content is being posted.</li>
                  <li *ngIf="cadence?.value === 'DAILY'" class="m--0">For daily event and offer posts, each post will be published and unpublished on the same days as the Event Days to ensure post schedule is in sync with event schedule.</li>
                  <li class="m--0">Depending on the {{postType?.value | titlecase}} Start Date and Time, you may choose to publish the post earlier, but not later.</li>
                </ul>
              </div>
              
              <section>
                <p class="mb--10 txt--14 fw--700">Publish {{cadence?.value | titlecase}} On *</p>

                <ng-container *ngIf="cadence?.value === 'DAILY'">
                  <p *ngIf="postType?.value !== 'STANDARD'" class="txt--14 m--0">{{daysUnified}}</p>

                  <section *ngIf="postType?.value === 'STANDARD'">
                    <ng-container class="w--auto d--inl-block">
                      <p class="mb--5 txt--14">Schedule is always set in local business time</p>
                      <div class="d-flex m-auto mt--10 mb--10">
                        <span class="frecuency-week-selector ml--0" (click)="selectDay(0)" [ngClass]="{'activate': publishDay?.value?.includes(0)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SU</span>
                        <span class="frecuency-week-selector" (click)="selectDay(1)" [ngClass]="{'activate': publishDay?.value?.includes(1)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">MO</span>
                        <span class="frecuency-week-selector" (click)="selectDay(2)" [ngClass]="{'activate': publishDay?.value?.includes(2)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TU</span>
                        <span class="frecuency-week-selector" (click)="selectDay(3)" [ngClass]="{'activate': publishDay?.value?.includes(3)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">WE</span>
                        <span class="frecuency-week-selector" (click)="selectDay(4)" [ngClass]="{'activate': publishDay?.value?.includes(4)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">TH</span>
                        <span class="frecuency-week-selector" (click)="selectDay(5)" [ngClass]="{'activate': publishDay?.value?.includes(5)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">FR</span>
                        <span class="frecuency-week-selector" (click)="selectDay(6)" [ngClass]="{'activate': publishDay?.value?.includes(6)}" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">SA</span>
                      </div>
                      <p *ngIf="publishDay?.value?.length > 0" class="m--0 txt--dark--gray">{{publishDay.value.length}} days have been selected</p>
                      <mat-error class="mt--5" *ngIf="publishDay?.errors && form?.touched">Day of week is required</mat-error>
                    </ng-container>
                  </section>
                </ng-container>

                <div class="row col-12">
                  <section class="col-12 col-lg-3 p--0 mr--20" *ngIf="cadence?.value === 'WEEKLY'">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="repeatsOnWeekly"
                      placeholder="Start day"
                      [disabled]="isEdit && !isDraft"
                      required>
                      <mat-option *ngFor="let day of nameOfDaysOptions;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="repeatsOnWeekly?.errors && form?.touched">Publish weekly on is required</mat-error>
                  </section>

                  <section class="col-12 col-lg-3 p--0 mr--20" *ngIf="cadence?.value === 'MONTHLY'">
                    <mat-select class="input-group__field mr--10 bg--white"
                      formControlName="repeatsOnDay"
                      placeholder="Start day"
                      [disabled]="isEdit && !isDraft"
                      required>
                      <mat-option *ngFor="let day of daysList;" [value]="day?.value"> {{day?.displayName}} </mat-option>
                    </mat-select>
                    <mat-error class="mt--5" *ngIf="repeatsOnDay?.errors && form?.touched">Publish monthly on is required</mat-error>
                  </section>
          
                  <section class="col-12 p--0" [class.col-lg-3]="cadence?.value !== 'DAILY'">
                    <p class="mb--10 mt--20 txt--14 fw--700" *ngIf="cadence?.value === 'DAILY'">
                      Time of Publish *
                    </p>
                    <div class="col-12 row">
                      <section class="col-12 p--0" [class.col-lg-3]="cadence?.value === 'DAILY'">
                        <mat-form-field class="input-group input-group--override input-group--override-label">
                          <input type="text" class="input-group__field input--clock input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                            aria-label="Publish time"
                            matInput
                            formControlName="publishTime"
                            [placeholder]="'Time'"
                            [matAutocomplete]="auto"
                            (keydown)="validateInput($event)"
                            (blur)="formatTime($event, 'publishTime')"
                            (input)="convertToUppercase($event, 'publishTime')"
                            [readonly]="isEdit && !isDraft"
                            required>
                          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                              {{ hour }}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                        <mat-error class="position-error-label" *ngIf="publishTime?.errors && form?.touched">Time of publish is required</mat-error>
                      </section>

                      <p *ngIf="postType?.value !== 'STANDARD' && cadence?.value === 'DAILY'" class="txt--14 m--0 ml--5 mt--15 col-12 col-lg-3 p--0">until {{getEndDate()}}</p>
                    </div>
                  </section>

                  <section *ngIf="postType?.value !== 'STANDARD' && cadence?.value !== 'DAILY'" class="col-12 p--0">
                    <p class="mb--10 txt--14 fw--700">Until *</p>
                    <p class="txt--14 m--0 first-letter-uppercase">{{getEndDate()}} at {{getEndTime()}}</p>
                  </section>
                </div>
              </section>
            </div>

          </section>
          
          <ng-container id="startDateSchedule" class="mb--20">
            <p class="mb--10 mt--20 txt--14 fw--700">
              Series Starts On (Optional)
              <app-info-tooltip [tooltip]="'Your posts series will start publishing on the upcoming scheduled date, or you can schedule it to start at a later date.'"></app-info-tooltip>
            </p>

            <div class="col-12 row">
              <section class="col-12 col-lg-3 p--0 mr--20">
                <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block">
                  <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="isEdit && !isDraft"
                  placeholder="Enter a Date"
                  [min]="getMinDate()"
                  formControlName="firstPublishDate"
                  [matDatepicker]="firstPublishDateCalendar"
                  (click)="firstPublishDateCalendar.open()"
                  (dateChange)="addFormFieldsStepThree()"
                  [readonly]="isEdit && !isDraft"
                  [disabled]="isEdit && !isDraft">
                  <mat-datepicker #firstPublishDateCalendar></mat-datepicker>
                </mat-form-field>
              </section>
            </div>
          </ng-container>

          <ng-container *ngIf="cadence?.value != 'INSTANT'">
            <p class="mb--10 txt--14 fw--700">Series Ends *
              <app-info-tooltip [tooltip]="'Choose whether this recurring post will continue indefinitely or stop after a specific date or number of occurences.'"></app-info-tooltip>
            </p>
            <mat-radio-group class="w--auto d--inl-block" formControlName="endsOn" (change)="addFormFieldsStepThree()" [disabled]="isEdit && !isDraft" matTooltipPosition="above" [matTooltip]="getDisabledFieldTooltip()">
              <mat-radio-button [value]="'never'" class="txt--14 d--block p--0 mb--10">Never</mat-radio-button>
              
              <section class="d--block">
                <mat-radio-button [value]="'custom_occurrences'" class="txt--14 d--inl-block vertical--align--top p--0 mb--10 mt--10 mr--5">After</mat-radio-button>
                <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                  <input matInput type="number" class="w--40 fw--regular p--10" [class.field-disabled]="endsOn?.value != 'custom_occurrences' || (isEdit && !isDraft)" formControlName="endsOnAfterOcurrences"
                  [readonly]="endsOn?.value != 'custom_occurrences' || (isEdit && !isDraft)">
                  <p class="vertical--align--top d--inl-block m--0 mt--10 ml--5">Occurences</p>
                </mat-form-field>
                <mat-error class="position-error-label" *ngIf="endsOnAfterOcurrences?.errors && form?.touched">Occurences is required</mat-error>
              </section>

              <mat-radio-button [value]="'custom_date'" class="txt--14 d--inl-block vertical--align--top p--0 mr--5 mt--10 mb--10">On</mat-radio-button>
              <mat-form-field class="input-group input-group--override input-group--override-label d--inl-block w--70">
                <input matInput class="input-group__field input--calendar input-icon--right" [class.field-disabled]="endsOn?.value !== 'custom_date' || (isEdit && !isDraft)"
                placeholder="Enter a Date"
                [min]="getMinDate()"
                formControlName="endsOnCustomDate"
                [matDatepicker]="endsOnCalendar"
                (click)="endsOnCalendar.open()"
                [disabled]="endsOn?.value !== 'custom_date' || (isEdit && !isDraft)"
                [readonly]="endsOn?.value !== 'custom_date' || (isEdit && !isDraft)">
                <mat-datepicker #endsOnCalendar></mat-datepicker>
              </mat-form-field>
              <mat-error class="position-error-label" *ngIf="endsOnCustomDate?.errors && form?.touched">On date is required</mat-error>
            </mat-radio-group>
            <mat-error class="position-error-label" *ngIf="endsOn?.errors && form?.touched">Series Ends is required</mat-error>
          </ng-container>

        </section>
      </form>

    </div>
  </cdk-step>

  <cdk-step [completed]="true">
    <ng-container *ngIf="step == 3">

      <div class="alert alert-danger w--100" role="alert" *ngIf="isCorrectData">
        <em class="fas fa-exclamation-triangle mr--10"></em>
        
        <span>There are some errors with your post, please review the <span *ngIf="fieldsWithError?.length">following</span> fields and try again.</span>
        <ul class="mt--5 errors-list">
          <li *ngFor="let error of fieldsWithError" class="mb--0">{{error?.field | titlecase}} - {{error?.error}}</li>
        </ul>
      </div>


      <div class="dialog-row p--0 m--0 pl--20 pr--20">
        <p class="d-block mt--20 m--0 txt--md fw--700">Post Preview</p>
      </div>

      <div class="bg--off--white p--20 pb--0">
        <div class="image-section pb--20 w--100">
          <section class="info-section">
            <div class="selected-thumb">
              <ng-container *ngIf="mediaUrlArray?.length >= 1">
                <img 
                    [src]=" mediaUrlArray[selectedFileIndex]?.url" 
                    [alt]="mediaUrlArray[selectedFileIndex]?.url">
                <!-- <div class="custom-swiper-cont">
                  <div class="custom-swiper-prev" (click)="customSwiperPrev()" *ngIf="selectedFileIndex > 0">
                    <i class="far fa-chevron-left"></i>
                  </div>
                  <div class="custom-swiper-thumb">
                    <img 
                    [src]="mediaUrlArray[selectedFileIndex]?.category === 'VIDEO' 
                    ? mediaUrlArray[selectedFileIndex]?.preview 
                    : mediaUrlArray[selectedFileIndex]?.url" 
                    [alt]="mediaUrlArray[selectedFileIndex]?.url">
                  </div>
                  <div class="custom-swiper-next" (click)="customSwiperNext()" *ngIf="mediaUrlArray.length -1 > selectedFileIndex">
                    <i class="far fa-chevron-right"></i>
                  </div>
                </div> -->
              </ng-container>
              <ng-container *ngIf="mediaUrlArray?.length === 0">
                <div class="custom-swiper-cont">
                  <div class="custom-swiper-thumb no-media-attached">
                    <div class="no-files-cont">
                      <img src="/assets/images/no_files_attached.svg" alt="">
                      <p>No image added</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            
            <div class="p--20">
              <div class="summary-field">
                <p *ngIf="postType?.value !== 'STANDARD'" class="d-block mb--20 txt--lg">{{title?.value | titlecase}}</p>
                <read-more [text]="formattedSummary" [showToggleButton]="true" [maxLength]="300"></read-more>
              </div>
              <p *ngIf="actionType?.value && actionType?.value !== 'NONE'" class="txt--14 m--0 mt--20 txt--gray txt-actionType">{{getActionType(actionType?.value) | titlecase}}</p>
            </div>
          </section>
        </div>

        <div *ngIf="cadence?.value !== 'INSTANT'" class="alert alert-notification-dark w--100 mt--20">
          <span class="icon-info-message col-2 vertical--align--top">
            <em class="fas fa-info"></em>
          </span>
          <p class="col-10 display-in-bl">Please review the post publishing settings. Once a recurring post is scheduled or published, the publish settings cannot be changed, and you'll need to recreate the post by either cloning it or starting from scratch.</p>
        </div>
      </div>

      <div class="row ml--0">
        <div class="dialog-row bg--off--white p--20 pb--0 col-6 display-in-bl">
          <div class="dialog-row p--0 mb--20">
            <p class="d-block mb--20 txt--md fw--700">Post Settings:</p>
          </div>

          <div>
            <section>
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Frequency:</p>
              <p class="fw--normal">{{dataForLastStep?.cadence | titlecase}}</p>
            </section>

            <section>
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Post Type:</p>
              <p class="fw--normal">{{dataForLastStep?.postType | titlecase}}</p>
            </section>

            <section *ngIf="dataForLastStep?.repeatsOn">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Publishes On:</p>
              <p class="fw--normal" [innerHTML]="dataForLastStep.repeatsOn"></p>
            </section>

              <section *ngIf="dataForLastStep?.schedulePublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Scheduled Publish:</p>
                <p class="fw--normal">{{dataForLastStep.schedulePublish}}</p>
              </section>

              <section *ngIf="dataForLastStep?.timeOfPublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Time of Publish:</p>
                <p class="fw--normal">{{dataForLastStep.timeOfPublish}}</p>
              </section>

              <section *ngIf="dataForLastStep?.scheduledDetele">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Scheduled Delete:</p>
                <p class="fw--normal">{{dataForLastStep.scheduledDetele}}</p>
              </section>

              <section *ngIf="dataForLastStep?.timeOfDetele">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Time of Delete:</p>
                <p class="fw--normal">{{dataForLastStep.timeOfDetele}}</p>
              </section>

              <section *ngIf="dataForLastStep?.firstPublish">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Series Starts On:</p>
                <p class="fw--normal">{{dataForLastStep.firstPublish}}</p>
              </section>

              <section *ngIf="dataForLastStep?.endsOn">
                <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Series Ends:</p>
                <p class="fw--normal">{{dataForLastStep.endsOn}}</p>
              </section>

          </div>
        </div>

        <div class="dialog-row bg--off--white p--20 pb--0 col-6 display-in-bl" *ngIf="postType?.value !== 'STANDARD'">
          <div class="dialog-row p--0 mb--20">
            <p class="d-block mb--20 txt--md fw--700">{{postTypeLabel | titlecase}} Details:</p>
          </div>
          <div>

            <section>
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">
                {{postType?.value | titlecase}} <span *ngIf="cadence?.value !== 'DAILY'">Start</span> {{cadence?.value === 'MONTHLY' ? 'Date:' : 'Day:'}}
              </p>
              <p *ngIf="cadence?.value === 'DAILY'" class="fw--normal">{{dataForLastStep?.repeatsOn}}</p>
              <p *ngIf="cadence?.value !== 'DAILY'" class="fw--normal">
                {{dataForLastStep?.startDate}} <span *ngIf="dataForLastStep?.startTime">at {{dataForLastStep?.startTime}}</span>
              </p>
            </section>

            <section *ngIf="cadence?.value !== 'DAILY'">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} End {{cadence?.value === 'MONTHLY' ? 'Date:' : 'Day:'}}</p>
              <p *ngIf="cadence?.value !== 'DAILY'" class="fw--normal">
                {{dataForLastStep?.endDate}} <span *ngIf="dataForLastStep?.endTime">at {{dataForLastStep?.endTime}}</span>
              </p>
            </section>

            <section *ngIf="dataForLastStep?.startTime && dataForLastStep?.endTime && cadence?.value === 'DAILY'">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">{{postType?.value | titlecase}} Time:</p>
              <p class="fw--normal">{{dataForLastStep?.startTime}} to {{dataForLastStep?.endTime}}</p>
            </section>

            <section *ngIf="dataForLastStep?.couponCode">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Coupon Code Name:</p>
              <p class="fw--normal">{{dataForLastStep?.couponCode}}</p>
            </section>

            <section *ngIf="dataForLastStep?.linkToRedeem">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Link to Redeem Offer:</p>
              <p class="fw--normal">{{dataForLastStep?.linkToRedeem}}</p>
            </section>
              
            <section *ngIf="dataForLastStep?.termsAndCondition">
              <p class="txt--14 fw--700 d--inl-block m--0 mr--10">Terms and Conditions:</p>
              <p class="fw--normal">{{dataForLastStep?.termsAndCondition}}</p>
            </section>

          </div>
        </div>
      </div>

      <div class="dialog-row p--20 pt--0">
        <mat-accordion displayMode="flat" class="no-shadow no-hover">
          <mat-expansion-panel>
            <mat-expansion-panel-header class="p--0 mt--5" style="height: 50px!important;">
              <mat-panel-title>
                <p class="txt--md txt--blue m--0 fw--700 mb--25 pl--0">Profiles</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let loc of locationsSelected">
              <p class="fw--medium txt--14 m--0">{{loc?.locationName}} | 
              <gmb-address class="txt--dark--gray vertical--align--middle display-in-bl" [address]="loc?.address" [serviceAreas]="loc?.serviceArea?.places?.placeInfos">
              </gmb-address></p>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </ng-container>
  </cdk-step>
</app-modal-stepper>