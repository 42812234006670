// dep
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';

// app
import { 
  GoogleMediaItem, 
  ModalCategories,
  CATEGORIES_DATA, 
  ImageRequirement, 
  Categories 
} from 'src/app/constants/google-media';
import { TypeImages } from 'src/app/constants/types';
import { Messages } from 'src/app/constants/messages';
import { ModalConfirmData } from 'src/app/classes/modal-confirm-data';
import { GoogleService } from 'src/app/services/google.service';
import { StorageImageService } from 'src/app/services/storage-image.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LocationService } from 'src/app/services/location.service';
import { FormInputUploadComponent } from '../../form-input-upload/form-input-upload.component';
import { ModalShowMediaComponent } from '../../modal-show-media/modal-show-media.component';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { UploadedMedia } from '../../form-input-upload/form-input-upload.component';

@Component({
  selector: 'app-photos-location',
  templateUrl: './photos-location.component.html',
  styleUrls:  ['./photos-location.component.scss']
})
export class PhotosLocationComponent extends BaseComponent implements OnInit {
  imageUrl : {url : string, 
              preview: string | null, 
              fileName: string | null, 
              description: string | null }[] = [];
  imageSelectedUrl: string = null;
  previewImage: string = null;
  // thumbNailImageUrl: string = null; // TODO: Unused, remove
  categories = CATEGORIES_DATA;
  fileUrl: string;
  description: string;
  public listFiles = new ModalCategories();
  category: { name: string } = { name: 'CATEGORY_UNSPECIFIED'};
  isUploading = false;
  public percentUpload: number;
  isProgress = true;
  isProgressPublish = false;
  selectedCategory: Categories | null = { name: 'COVER', value: 'COVER' };
  
  public imageRequirements: ImageRequirement = {
    accepts: '.png, .jpg, .jpeg, .webp',
    type: ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'],
    min_width: 480,
    min_height: 270,
    max_width: 2120,
    max_height: 1192,
    min_size: 10240,
    minRatio: 1.3
  };

  @Input() accountId: string = null;
  @Input() locationId: string = null;
  
  @ViewChild('inputUpload', {static: false}) 
  inputUpload: FormInputUploadComponent;
  @ViewChild('tabGroup', { static: true }) 
  tabGroup: MatTabGroup;

  @Input() locationType = '';
  @Input() showOnlyMedia = false;
  @Input() bulk = false;
  @Input() error:any = {};
  @Input() error_details:any = [];

  private _mediaFormat: TypeImages;

  // TODO: Unused, remove.
  // locationCategories = {
  //   restaurant: false,
  //   hotel: false,
  //   normal: false
  // };

  public readonly SWIPER_CONF = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true,
    slideToClickedSlide: true
  } as const;

  private _refSubscription;
  private _deleteSubcription;

  constructor(
    public dialog: MatDialog,
    private _googleS: GoogleService,
    private _storageImageS: StorageImageService,
    private _snackS: SnackbarService,
    private  activateRoute: ActivatedRoute,
    private _sessionS: SessionService,
    private _locationS: LocationService,
  ) {
    super();
  }

  ngOnInit() :void {
    if (!this.bulk) {
      if (this.locationType === '') {
        this.locationId = this.activateRoute.snapshot.parent.params.locationId;
        this.accountId  = this.activateRoute.snapshot.parent.params.accountId;
        this._locationS.getRef(this._sessionS.getSession().gid, this.accountId, this.locationId).subscribe( 
        (data) => {
          let tmpCategory = CATEGORIES_DATA;
          const lodging    = data.location.locationState?.canOperateLodgingData || false
          const restaurant = data.location.locationState.canHaveFoodMenus || false
          if (!restaurant && !lodging) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK' && 
                                                          category.value !== 'ROOMS' && category.value !== 'COMMON_AREA')
          } else if (!restaurant) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'MENU' && category.value !== 'FOOD_AND_DRINK')
          } else if (!lodging ) {
            tmpCategory = tmpCategory.filter( category => category.value !== 'ROOMS' && category.value !== 'COMMON_AREA')
          }
          this.categories = tmpCategory;
          this._fetchMedia();
        });
      } else {
        this._fetchMedia();
      }
    } else if (this.accountId && this.locationId) {
      this._fetchMedia();
    } else {
      this.isProgress = false;
    }

    this._setMinRatio();
  }

  ngOnDestroy(): void {
    /* FIXME: smell bad */ 
    if (this._refSubscription) {
      this._refSubscription.unsubscribe();
    }
    if (this._deleteSubcription) {
      this._deleteSubcription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  // TODO: Getter called from Template, slowdown.
  public get videoList() : GoogleMediaItem[] {
    return this.listFiles.ADDITIONAL.filter(l => l.mediaFormat === 'VIDEO')
  }

  private async _fetchMedia() : Promise<void> {
    const media = await this._googleS.fetchLocationMedia(this.accountId, this.locationId);
    this.isProgress = false;
    this.listFiles = new ModalCategories();
    for(const m of media) {
      this.listFiles[m.locationAssociation.category]?.push(m);
    }
  }


  public tabChange($event: MatTabChangeEvent, input ) : void {
    if (this._refSubscription) {
      this._refSubscription.unsubscribe();
      this._deleteSubcription.unsubscribe();
    }

    if (!this.showOnlyMedia) {
      const changeCategory  = $event.tab.textLabel;
      const selectedCategory = this.categories.find(c => c.name === changeCategory);

      if (selectedCategory.name === 'VIDEO') {
        this.imageRequirements.accepts = '.mp4';
        this.imageRequirements.type = ['video/mp4'];
      } else if (selectedCategory.name === 'COVER' || selectedCategory.name === 'PROFILE') {
        this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm';
        this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
      } else {
        this.imageRequirements.accepts = '.png, .jpg, .jpeg, .webpm, .mp4';
        this.imageRequirements.type = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'video/mp4'];
      }

      this.selectedCategory = selectedCategory;
      this.fileUrl = '';
      this.description = '';
      this._clearAll();
    }

    this._setMinRatio();
  }

  private _setMinRatio() : void {
    this.imageRequirements.minRatio = (this.selectedCategory.value === 'COVER' ? 1.7 : 1.3);
  }

  /**
   * After the photos/videos were uploaded to Firebase Storage, the user can click
   * the "publish" button to send that media to Google. 
   */
  public onPublishClicked(category): void {
    for(const media of this.imageUrl) {
      if (media.fileName === undefined) {
        continue;
      }
      this.isProgressPublish = true;
      // FIXME: Here 'this.category' wrongly is assigned to an string??
      this.category = category;
      const mediaItem = {
        mediaFormat: this._mediaFormat,
        locationAssociation: {category},
        sourceUrl: media.url,
        description: media.description
      };

      // TODO: saveMedia's are executed in parallel, is that ok?
      this._saveMedia(mediaItem /*, category*/);
    }
  }

  private async _saveMedia(mediaItem : Pick<GoogleMediaItem, 'mediaFormat' | 'locationAssociation' | 
                                                             'sourceUrl' | 'description'> /*, category*/) : Promise<void> {
    try { 
      await this._googleS.saveLocationMedia(this.accountId, this.locationId, mediaItem);
      this._snackS.openSuccess(Messages.upload.SEND_SUCCESS);
      this._removeRefs(/*category*/);
    } catch (e) {
      console.error(e);
      this._snackS.openError('Error publishing the image, try again');
    }
  }

  private _removeRefs(/*category*/) : void {
    this._refSubscription = this._storageImageS.ref$.subscribe(fileRef => {
      this.description = "";
      this.isProgressPublish = false;
      this._deleteSubcription = fileRef.delete().subscribe(() => {
        this._fetchMedia();
        this._clearAll();
      });
    });
  }

  fileChanged(target: EventTarget) : void {
    this._storageImageS.fileChanged(target as HTMLInputElement, true, null, this.locationId);

    // FIXME: Multiple subscriptions
    this._subscribeSafe(this._storageImageS.url$, url => {
      if (url) {
        this.imageSelectedUrl = url.url;
        // this.thumbNailImageUrl = url.preview; // TODO: Unused, remove.
        this._mediaFormat = url.type;
        this.fileUrl = url.url;
      }

    });

    // TODO: Unused, remove. 
    // this.storageImageS.ref$.subscribe(fileRef => {
    //   this.fileRef = fileRef;
    // });
  }


  /**
   * Called after the media was uploaded to Firebase Storage.
   * (but before is published to  Google).
   */
  public handleMediaUploaded(media : UploadedMedia | null, added = false) : void {
    if (!media) 
      return;

    const url = media.url;
    this._mediaFormat = media.mediaFormat;
    this.isUploading = true;

    if (this.getimage(url) === -1) {
      const index = this.imageUrl.length > 0 ? this.imageUrl.length : 0;
      this.imageUrl.push({ url,
                           preview: (media.mediaFormat === "VIDEO" ? media.preview : url),
                           description: null,
                           fileName: media.fileName
                         });
      if (!added) {
        this.imageSelectedUrl = this.imageUrl[index].url;
      }

    } else if (this.imageSelectedUrl) {
      this.imageSelectedUrl = url;    
      this.previewImage = media.preview || url;
    }

    this.fileUrl = url;
    if (url) {
      this.isUploading = false;
    }
  }

  getimage(url : string) : number {
    return this.imageUrl.findIndex(i => i.url === url);
  }

  public changeDescription() : void {
    const index = this.getimage(this.imageSelectedUrl);
    if (index > -1) { 
      this.imageUrl[index].description = this.description; 
    }
  }

  public changePreview(url : string | null) : void {
    if (!url)
      return;

    const index = this.getimage(url);
    this.description = (index != -1 ? this.imageUrl[index].description : null);
    this.imageSelectedUrl = url;
    this._storageImageS.setImageUrl(url);
  }

  public clearImagen() : void {
    const indexImage = this.getimage(this.imageSelectedUrl);
    this.imageUrl.splice(indexImage, 1);
  }

  public removeImagen() : void {
    this.clearImagen();
    this.changePreview(this.imageUrl[0]?.url || null);
    if (!this.imageUrl.length) { 
      this.inputUpload.reset(); 
    }
  }

  private _clearAll() : void {
    this.imageSelectedUrl = null;
    this.imageUrl = [];
    this.inputUpload.reset();
  }

  public addNewImage() : void {
    this.imageSelectedUrl = null;
    const url = '/assets/images/camera.svg';
    this.imageUrl.push({url, preview: url, fileName: null, description: null});
    this.changePreview(url);
  }

  public showMedia(filelist : GoogleMediaItem[], file : GoogleMediaItem, category, index : number) : void {
    const dialogRef = this.dialog.open(ModalShowMediaComponent,
      {
        width: '680px',
        data: new ModalConfirmData({
          data: {filelist, 
                 media: file, 
                 category, 
                 index, 
                 locationId: this.locationId, 
                 accountId: this.accountId},
          title: null,
          content: null,
          confirmButtonLabel: 'Save',
          closeButtonLabel: 'Cancel'
        })
    });
    dialogRef.disableClose = true;
    const Subs = dialogRef.componentInstance.onDelete$.subscribe( item => {
      if(item !== null)
        this._fetchMedia();
    })

    dialogRef.afterClosed().take(2).subscribe(() => {
      // unsubscribe onAdd
      Subs.unsubscribe();
    });
  }

  public getResult(): Pick<GoogleMediaItem, 'mediaFormat' | 'locationAssociation' | 'sourceUrl' | 'description'>[] | undefined {
    if (this.selectedCategory?.value) {
      return this.imageUrl.map(el => ({ mediaFormat: this._mediaFormat,
                                        locationAssociation: {category: this.selectedCategory.value},
                                        sourceUrl: el.url,
                                        description: el.description}))
    }
  }

}
