// dep
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {SwiperComponent} from "ngx-swiper-wrapper";
declare var Swiper: any;

// app
import {ModalDeleteMediaComponent} from '../modal-delete-media/modal-delete-media.component';
import {ModalService} from '../../services/modal.service';
import {GoogleService} from '../../services/google.service';
import {SnackbarService} from '../../services/snackbar.service';
import { GoogleMediaItem } from 'src/app/constants/google-media';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-show-media',
  templateUrl: './modal-show-media.component.html',
  styleUrls:  ['./modal-show-media.component.scss']
})
export class ModalShowMediaComponent implements AfterViewInit {
  @ViewChild('gallery') 
  gallery: SwiperComponent;
  
  @ViewChild('galleryThumbs') 
  galleryThumbs: SwiperComponent;

  onDelete$ = new BehaviorSubject<GoogleMediaItem| null>(null);

  private readonly data: { filelist : GoogleMediaItem[], 
                           index : number, 
                           media : any, 
                           category : any, 
                           locationId : string, 
                           accountId : string } 
  category: { value: string };
  public mediaFiles: GoogleMediaItem[];
  index: number;
  media: GoogleMediaItem;
  
  public readonly GALLERY_THUMBS_CONF = {
    slidesPerView: 4,
    initialSlide: 0,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    simulateTouch: true,
    slideToClickedSlide: true
  } as const;

  public readonly GALLERY_CONF = {
    loop: true,
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 0, // Space between each Item,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    grabCursor: true
  } as const;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public config: any,
    public dialog: MatDialogRef<ModalShowMediaComponent>,
    private _modalS: ModalService,
    private _googleS: GoogleService,
    private _snackS: SnackbarService,
    private _sessionS: SessionService
  ) {
    this.data = this.config.data;
    this.mediaFiles = this.data.filelist;
    this.media = this.data.media;
    this.index = this.data.index;
    this.category = this.data.category;
  }

  ngAfterViewInit(): void {
    this.gallery.directiveRef.setIndex(this.index);
    this.galleryThumbs.directiveRef.setIndex(this.index);
  }

  changeGalleryIndex(e) : void {
    if ( e.target.tagName.toLowerCase() === 'img' ) {
      const thumbIndex = this._getSlideIndex(e.target.parentNode);
      this.gallery.directiveRef.setIndex(thumbIndex);
    }
  }

  private _getSlideIndex(el) : number {
    return [...el.parentNode.children].findIndex(c => c == el)
  }


  public openDelete(file : GoogleMediaItem) : void {
    this._modalS.openGenericModal(ModalDeleteMediaComponent, {media: file}, confirm => {
      if (confirm) {
        this._googleS.deleteMedia(this.data.accountId, this.data.locationId, file.name).subscribe(result => {
          this._snackS.openSuccess('Media item removed successfully');
          this.onDelete$.next(file)
          this.mediaFiles = this.mediaFiles.filter( mi => mi !== file);
        }, error => {
          this._snackS.openError('Delete media failed');
          console.error(error);
        });
      }
    });
  }

  public showCannotPlayVideoModal() : void {
    const n = this._sessionS.getDomain().branding.company_name;
    this._modalS.openErrorModal('Cannot play Video', 
                                `Sorry, we can only play videos that have been uploaded using ${n}.`);
  }

}
