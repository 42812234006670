// dep
import { of, BehaviorSubject, Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';

// app
import {Pageable} from '../constants/pageable';
import { ApiResponse, Pagination2 } from '../constants/api-response';
import { Post, SchedulePost } from '../constants/post';
import AccountReport from './account-report';
import { ResponseModel } from '../v3.models/response.model';
import { BulkPost, LocalPost, MetaPost } from '../v3.models/posts.models';
import { SnackbarService } from './snackbar.service';
import { Queue } from '../constants/firestore/enqueue';
import {environment} from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  public loading = new BehaviorSubject<boolean>(false);
  // postEndPoint = `${environment.apiUrlV3}/posts`;
  bulkPostEndPoint = `${environment.apiUrlV3}/bulk_posts`;
  localPostEndPoint = `${environment.apiUrlV3}/posts`;


  private responsePosts: BehaviorSubject<{
    response: LocalPost | BulkPost, type: 'BULK' | 'LOCAL',
    tab: 'Posted' | 'Pending' | 'Drafts' | 'Archive'
  }> = new BehaviorSubject(null);
  posts$ = this.responsePosts.asObservable();

  constructor(
    private _http: HttpClient,
    private _snack: SnackbarService
  ) {
  }

  public isLoading(state: boolean): void {
    this.loading.next(state);
  }

// TODO: Unused, remove. 
//   getPosts(placeId, pageable: Pageable, sort?: string, direction?: string, postType?: string): Observable<Pagination> {
//     let params = new HttpParams();
//     if (pageable) {
//       params = params.append('page', pageable.page.toString());
//       params = params.append('size', pageable.size.toString());
//     }
//     if (postType) params = params.append('post_type', postType);
// 
//     if (sort) {
//       params = params.append('sort', sort);
//     }
// 
//     if (direction) {
//       params = params.append('direction', direction);
//     }
// 
//     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/${placeId}`, {params})
//       .pipe(
//         map(response => response.data as Pagination || null)
//       );
//   }

// TODO: Not used, remove?
//
//   getWithPostManagement(pageable: Pageable, sort?: string, direction?: string) {
//     let params = new HttpParams();
//     if (pageable) {
//       params = params.append('page', pageable.page.toString());
//       params = params.append('size', pageable.size.toString());
//     }
//     if (sort) {
//       params = params.append('sort', sort);
//     }
// 
//     if (direction) {
//       params = params.append('direction', direction);
//     }
// 
//     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement`, {params})
//       .pipe(
//         map(response => response.data as Pagination || null)
//       );
//   }
// 
//   getWithPostManagementByType(pageable: Pageable, type: string, sort?: string, direction?: string) {
//     let params = new HttpParams();
//     if (pageable) {
//       params = params.append('page', pageable.page.toString());
//       params = params.append('size', pageable.size.toString());
//     }
// 
//     if (sort) params = params.append('sort', sort);
// 
//     if (direction) params = params.append('direction', direction);
// 
//     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${type}`, {params })
//       .pipe(
//         map(response => response.data as Pagination || null)
//       );
//   }

  // update(accountId, locationId, postId, data: Post) {
  //   if (locationId) {
  //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/${postId}`, data);
  //   } else {
  //     postId = _.get(postId, '$oid', postId);
  //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${postId}`, data);
  //   }
  // }

  validate_placeholders(accounts: AccountReport[], summary: string) {
    return this._http.post<ApiResponse>(
      `${environment.apiUrl}/v2/posts/postManagement/filter_summary`,
      {accounts, summary},
    )
    .pipe(
      map(response => response.data || null)
    );
  }

//   delete(accountId, locationId, postId, isEdit= false) {
//     if (locationId) {
//       return this._http.delete<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/${postId}`);
//     } else {
//       const headers = new HttpHeaders({'x-delete' : String(isEdit)})
// 
//       postId = _.get(postId, '$oid', postId);
//       return this._http.delete<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${postId}`, {headers});
//     }
//   }

  // TODO: Not used, remove?
  //
  // scheduledDelete(accountId: string, locationId: string, postId: string, schedule: SchedulePost): Observable<ApiResponse> {
  //   let params = new HttpParams();
  // 
  //   if (schedule) {
  //     params = params.append('date', schedule.date.toString());
  //     params = params.append('time', schedule.time);
  //   }
  // 
  //   if (locationId) {
  //     return this._http.delete<ApiResponse>(
  //       `${environment.apiUrl}/v2/posts/scheduled/${accountId}/${locationId}/${postId}`,
  //       {params}
  //     );
  //   } else {
  //     postId = _.get(postId, '$oid', postId);
  //     return this._http.delete<ApiResponse>(
  //       `${environment.apiUrl}/v2/posts/postManagement/scheduled/${postId}`,
  //       {params}
  //     );
  //   }
  // }
  //
  //
  // cancelScheduledDelete( locationId, postId) {
  //   if (locationId) {
  //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/scheduled/${postId}`, {});
  //   } else {
  //     postId = _.get(postId, '$oid', postId);
  //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/scheduled/${postId}`, {});
  //   }
  // }
  //
  // save(accountId, locationId, post: any) {
  //   if (locationId) {
  //     return this._http.post<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}`, post);
  //   } else {
  //     return this._http.post<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement`, post);
  //   }
  // }
  //
  //
  // getPendingPost(pageable: Pageable, gid, accountId, locationId: string, next, last) {
  //   return this.fs.paginate<Protocol>(`${GROUPS}/${gid}/${ACCOUNTS}/${accountId}/${LOCATIONS}/${locationId}/${POST_MANAGEMENT_LOCATION}`,
  //     'status', ref => ref.where('status', 'in', ['PENDING', 'DRAFT', 'SCHEDULE']),
  //     {
  //       size: pageable.size,
  //       page: pageable.page
  //     }, false, next, last);
  // }
  // 
  // saveAll(accountId: string, locationId: any, enqueue: any) {
  //   let params = new HttpParams();
  //   if (enqueue && environment.queuesEnabled) {
  //     params = params.append('enqueue', enqueue);
  //   }
  // 
  //   return this._http.post(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/all`, {}, {params});
  // }

  saveV3All(gid: string, accountId: string, locationId: string, enqueue : 'enqueue' | typeof Queue.COMBINED_EXPRESS = Queue.COMBINED_EXPRESS ): Promise<void> {
    let params = new HttpParams();
    if (environment.queuesEnabled) {
      params = params.append('enqueue', enqueue);  // TODO: Ignored by backend, remove all 'enqueue' parameters
    }

    return this._http.post<void>(
      `${this.localPostEndPoint}/group/${gid}/account/${accountId}/location/${locationId}/google`,
      {},
      {params}
    ).toPromise();
  }

  purgeGmbDeleted(gid: string, accountId: string, locationId: string): Promise<void> {
    return this._http.post<void>(
      `${this.localPostEndPoint}/purge/${gid}/${accountId}/${locationId}`,
      {}
    ).toPromise();
  }

// TODO: Not used, remove??
//
//   getScheduledPosts(pageable: Pageable, sort?: string, direction?: string): Observable<Pagination> {
//     let params = new HttpParams();
//     if (pageable) {
//       params = params.append('page', pageable.page.toString());
//       params = params.append('size', pageable.size.toString());
//     }
// 
//     if (sort) {
//       params = params.append('sort', sort);
//     }
// 
//     if (direction) {
//       params = params.append('direction', direction);
//     }
// 
//     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/pending`, {params})
//       .pipe(
//         map(response => response.data as Pagination || null)
//       );
//   }

  transformImage(path): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/v2/posts/image`, { path });
  }

  // TODO: Not used, remove?
  // getCountersPosts(posts: any[]) {
  //   return this._http.post<any[]>(`${environment.apiUrl}/v2/posts/getCounters`, { posts });
  // }

  /**
   * Endpoints v3
   */

   getLocalPost(
     accountId: string,
     locationId: string,
     status: string,
     pageable: Pageable,
     sort?: string,
     direction?: string
    ): Promise<Pagination2<LocalPost>> {
    let params = new HttpParams();
    if (pageable) {
      params = params.append('page', pageable.page.toString());
      params = params.append('size', pageable.size.toString());
    }

    if (sort) {
      params = params.append('sort', sort);
    }

    if (direction) {
      params = params.append('direction', direction);
    }

    return this._http.get<ResponseModel<Pagination2<LocalPost>>>(
      `${this.localPostEndPoint}/account/${accountId}/location/${locationId}/${status}`,
      {params}
    ).pipe(
      map( response => {
        if (response.status) {
          return response.data;
        } else {
          this._snack.openError(response.message, 1500);
          return null;
        }
      })
    ).toPromise();
  }

  getBulkPost(status: string, pageable: Pageable): Promise<Pagination2<BulkPost>> {
    let params = new HttpParams();
    if (pageable) {
      params = params.append('page', pageable.page.toString());
      params = params.append('size', pageable.size.toString());
    }

    return this._http.get<ResponseModel<Pagination2<BulkPost>>>(`${this.bulkPostEndPoint}/${status}`, {params}).pipe(
      map( response => {
        if (response.status) {
          return response.data;
        } else {
          this._snack.openError(response.message, 1500);
          return null;
        }
      })
    ).toPromise();
  }

  getBulkCounters(bulkPostId: string): Promise<{id: string, posted: number}> {

    return this._http.get<ResponseModel<{id: string, posted: number}>>(
      `${this.bulkPostEndPoint}/${bulkPostId}/get_counters`
    ).pipe(
      map( response => response.status ? response.data : null)
    ).toPromise();
  }

  getBulkLocationPost(
    bulkPostId: string,
    onlyErrors: 'true' | 'false' = 'true'
  ): Promise<{meta: MetaPost, hasError: boolean, error: unknown}[]> {
    let params = new HttpParams();
    params = params.append('onlyErrors', onlyErrors);
    return this._http.get<ResponseModel<{meta: MetaPost, hasError: boolean, error: unknown}[]>>(
      `${this.bulkPostEndPoint}/${bulkPostId}/location_post`,
      {params }).pipe(
      map( response => {
        if (response.status) {
          return response.data;
        } else {
          this._snack.openError(response.message, 1500);
          return null;
        }
      })
    ).toPromise();
  }

  saveV3(
    accountId: string,
    locationId: string,
    post: Post,
    type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts' | 'Archive',
    publishNow = false
  ): Promise<LocalPost | BulkPost> {
    if (type === 'LOCAL') {
      return this._http.post<ResponseModel<LocalPost>>(
        `${this.localPostEndPoint}/account/${accountId}/location/${locationId}`,
        {post, publishNow}
      ).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: response.data, type, tab});
            return response.data;
          } else {
            if (response.data) this._snack.openWarning(response.message, 10000);
            else this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    } else {
      setTimeout(() => {
        this.responsePosts.next({response: null, type, tab});
      }, 1500);

      return this._http.post<ResponseModel<BulkPost>>(`${this.bulkPostEndPoint}/`, {post, publishNow}).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: response.data, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    }
  }

  deleteV3(postId: string, type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts'  | 'Archive'): Promise<void> {
    if (type === 'LOCAL') {
      return this._http.delete<ResponseModel<void>>(`${this.localPostEndPoint}/${postId}`).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: null, type, tab });
            return response.data;
          } else {
            this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    } else {
      return this._http.delete<ResponseModel<void>>(`${this.bulkPostEndPoint}/${postId}`).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: null, type, tab });
            return response.data;
          } else {
            this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    }
  }


  updateV3(
    postId: string,
    post: Post,
    type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts' | 'Archive',
    publishNow = false
  ): Promise<LocalPost | BulkPost> {
    if (type === 'LOCAL') {
      return this._http.put<ResponseModel<LocalPost>>(`${this.localPostEndPoint}/${postId}`, { post, publishNow}).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: response.data, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    } else {
      return this._http.put<ResponseModel<BulkPost>>(`${this.bulkPostEndPoint}/${postId}`, {post, publishNow}).pipe(
        map( response => {
          if (response.status) {
            this.responsePosts.next({response: response.data, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 1500);
            return null;
          }
        })
      ).toPromise();
    }
  }

  retry(postId: string, type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts'  | 'Archive'): Promise<void> {
    if (type === 'LOCAL') {
      return this._http.post<ResponseModel<void>>(`${this.localPostEndPoint}/retry/${postId}`, {}).pipe(
        map( response => {
          if (response.status) {
            this._snack.openSuccess('The post has been retried!', 3500);
            this.responsePosts.next({response: null, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 3500);
            return null;
          }
        })
      ).toPromise();
    } else {
      return this._http.post<ResponseModel<void>>(`${this.bulkPostEndPoint}/retry/${postId}`, {}).pipe(
        map( response => {
          if (response.status) {
            this._snack.openSuccess('The bulk post has been retried!', 3500);
            this.responsePosts.next({response: null, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 3500);
            return null;
          }
        })
      ).toPromise();
    }
  }

  retryAll(postId: string, type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts'  | 'Archive'): Promise<void> {
    if (type !== 'LOCAL') {
      return this._http.post<ResponseModel<void>>(`${this.bulkPostEndPoint}/retry/all/${postId}`, {}).pipe(
        map( response => {
          if (response.status) {
            this._snack.openSuccess('The bulk post has been retried!', 3500);
            this.responsePosts.next({response: null, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 3500);
            return null;
          }
        })
      ).toPromise();
    }
  }

  copy(postId: string, type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts'  | 'Archive'): Promise<void> {
    if (type === 'LOCAL') {
      return this._http.get<ResponseModel<void>>(`${this.localPostEndPoint}/copy/${postId}`).pipe(
        map( response => {
          if (response.status) {
            this._snack.openSuccess('The post has been copied!', 3500);
            this.responsePosts.next({response: null, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 3500);
            return null;
          }
        })
      ).toPromise();
    } else {
      return this._http.get<ResponseModel<void>>(`${this.bulkPostEndPoint}/copy/${postId}`).pipe(
        map( response => {
          if (response.status) {
            this._snack.openSuccess('The bulk post has been copied!', 3500);
            this.responsePosts.next({response: null, type, tab});
            return response.data;
          } else {
            this._snack.openError(response.message, 3500);
            return null;
          }
        })
      ).toPromise();
    }
  }

   scheduledDeleteV3(
     postId: string,
     schedule: SchedulePost,
     cancel = false,
     type: 'BULK' | 'LOCAL', tab: 'Posted' | 'Pending' | 'Drafts' | 'Archive'
    ): Promise<LocalPost | BulkPost> {
    if (type === 'LOCAL') {
     return this._http.post<ResponseModel<LocalPost>>(
       `${this.localPostEndPoint}/delete/scheduled/${postId}`,
       { schedule, cancel}
       ).pipe(
      map( response => {
        if (response.status) {
          this._snack.openSuccess(response.message, 3500);
          this.responsePosts.next({response: null, type, tab});
          return response.data;
        } else {
          this._snack.openError(response.message, 2500);
          return null;
        }
      })
    ).toPromise();
    } else {
      return this._http.post<ResponseModel<BulkPost>>(
        `${this.bulkPostEndPoint}/delete/scheduled/${postId}`,
        { schedule, cancel},
        ).pipe(
          map( response => {
            if (response.status) {
              this._snack.openSuccess(response.message, 3500);
              this.responsePosts.next({response: null, type, tab});
              return response.data;
            } else {
              this._snack.openError(response.message, 3500);
              return null;
            }
          })
        ).toPromise();
    }
  }


  // TODO: Unused, remove?
  // clearErrorsV3(postId: string, type: 'BULK' | 'LOCAL'): Promise<LocalPost | BulkPost> {
  //   if (type === 'LOCAL') {
  //    return this._http.get<ResponseModel<LocalPost>>(
  //      `${this.localPostEndPoint}/clear_errors/${postId}`,
  //      ).pipe(
  //     map( response => {
  //       if (response.status) {
  //         this._snack.openSuccess('Clear errors!', 3500);
  //         const tab = 'Pending';
  //         this.responsePosts.next({response: null, type, tab});
  //         return response.data;
  //       } else {
  //         this._snack.openError(response.message, 2500);
  //         return null;
  //       }
  //     })
  //   ).toPromise();
  //   } else {
  //     // TODO: Bug? POST body is auth headers
  //     // TODO: Route not implemented?
  //     return this._http.post<ResponseModel<BulkPost>>(
  //       `${this.bulkPostEndPoint}/clear_errors/${postId}`,
  //       {headers: this.auth.headers.headers}).pipe(
  //         map( response => {
  //           if (response.status) {
  //             this._snack.openSuccess('The scheduled delete has been set!', 3500);
  //             const tab = 'Pending';
  //             this.responsePosts.next({response: null, type, tab});
  //             return response.data;
  //           } else {
  //             this._snack.openError(response.message, 3500);
  //             return null;
  //           }
  //         })
  //       ).toPromise();
  //   }
  // }

  // NEW POST
  getPostList(gid, isBulk = true, pagination?, sort?, status?, occurence?, accountId?, locationId?):Observable <any> {
    const data = {
      "gids": [gid],
      "filter": {
        "status": status?.status,
        "bulkStatus": status?.bulkStatus,
        "cadence": {
          "isRecurring": occurence
        }
      },
      "pageSize": pagination?.size,
      "page": pagination?.page,
      "sortKey": sort?.sortBy,
      "reverse": sort?.direction == 'ASC' ? false : true
    };

    if (isBulk) {
      return this._http.post(`${environment.coreApiUrl}/posts/bulk`, data);
    } else {
      data['accountIds'] = [accountId];
      data['locationIds'] = [locationId];

      return this._http.post(`${environment.coreApiUrl}/posts/location-view`, data);
    }
  }

  createPost(data):Observable <any> {
    return this._http.post(`${environment.coreApiUrl}/posts`, data);
  }

  editPost(data, postId):Observable <any> {
    return this._http.put(`${environment.coreApiUrl}/posts/${postId}`, data);
  }

  deletePost(postIds, date, time, deleteWholeSeries):Observable <any> {
    const data = {
      "post_ids": postIds,
      "date": date, 
      "time": time,
      "deleteWholeSeries": deleteWholeSeries
    }

    return this._http.request('delete', `${environment.coreApiUrl}/posts/bulk-delete`, {
      body: data
    });
  }

  deleteLocationsFromPost(postId, locationsId, date, time):Observable <any> {
    const data = {
      "post_ids": locationsId,
      "date": date, 
      "time": time,
      "deleteWholeSeries": false
    }
    
    return this._http.request('delete', `${environment.coreApiUrl}/posts/${postId}/locations/bulk-delete`, {
      body: data
    });
  }

  getPost(postId):Observable <any> {
    return this._http.get(`${environment.coreApiUrl}/posts/${postId}`);
  }

  copyPost(post: any, imageUrl = ''): Observable<any> { // will be POST and send a payload with the new img url
    
    // old functionality
    // return this._http.get(`${environment.coreApiUrl}/posts/${post._id}/copy`);

    // new functionality
    return this._http.post(`${environment.coreApiUrl}/posts/copy`, {postId: post._id, sourceUrl: imageUrl});
  }
  
  storageImgInFirestore(element: any): Observable<any> {
    // filename should be the name that comes from the URL of the image, usually at the last part of the url
    const imageUrl = element.post?.media[0]?.sourceUrl;
    if (imageUrl) {
      return this._fetchImageAsFile(imageUrl, `${element._id}.png`);
    } else {
      return of(null);
    }
 
  }

  private _fetchImageAsFile(imageUrl: string, fileName: string): Observable<File> {
    return new Observable<File>((observer) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // This is necessary according to Chat GPT for CORS
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
  
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], fileName, { type: blob.type });
              observer.next(file);
              observer.complete();
            } else {
              observer.error('Canvas to Blob conversion failed');
            }
          });
        } else {
          observer.error('Failed to get 2D context from canvas');
        }
      };
  
      img.onerror = (error) => {
        observer.error('Image loading failed');
      };
  
      img.src = imageUrl;
    });
  }
  
  getStatusPost(postId):Observable <any> {
    return this._http.get(`${environment.coreApiUrl}/posts/${postId}/locations`);
  }

  getInfoRejected(postId, instanceId):Observable <any> {
    return this._http.get(`${environment.coreApiUrl}/posts/${postId}/locations/${instanceId}`);
  }

  getActionLinks(accounts):Observable <any> {
    return this._http.post(`${environment.coreApiUrl}/posts/url-attributes`, { accounts });
  }

  getSchedulePreview(data):Observable <any> {
    return this._http.post(`${environment.coreApiUrl}/posts/dates`, data);
  }
  
  retryAllPosts(statusData: any[], callback?: () => void): void {
    if (!statusData || !statusData.length) {
      this._snack.openError('Invalid post instance ID');
      return;
    }
  
    const postInstanceIds: string[] = statusData.map(item => item.postInstanceId).filter(id => id);
  
    if (!postInstanceIds?.length) {
      this._snack.openError('Invalid post instance ID');
      return;
    }
  
    this.retryPosts(postInstanceIds, callback);
  }
  
  retryPost(element: any, callback?: () => void): void {
    if (!element?.postInstanceId) {
      this._snack.openError('Invalid post instance ID');
      return;
    }
  
    this.retryPosts([element?.postInstanceId], callback);
  }
  
  private retryPosts(postInstanceIds: string[], callback?: () => void): void {
    const body = { postInstanceIds: postInstanceIds };
  
    this._http.post(`${environment.coreApiUrl}/posts/retry-locations`, body).subscribe({
      next: () => {
        this._snack.openSuccess('Retrying the posts succeeded');
        if (callback) {
          callback();
        }
      },
      error: () => {
        this._snack.openError('There was an error while retrying. Please try again or contact support');
      }
    });
  }

  retrievePost(gid, accountId, locationId):Observable <any> {
    return this._http.get(`${environment.coreApiUrl}/posts/${gid}/${accountId}/${locationId}/retrieve`);
  }  

  getOrdinalNumbers(number): string {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  }

  generateOrdinalDays(start: number, end: number) {
    const ordinalSuffixes = { 1: 'st', 2: 'nd', 3: 'rd' };
    const days = [];
    for (let i = start; i <= end; i++) {
      const suffix = ordinalSuffixes[i % 10] && ![11, 12, 13].includes(i) ? ordinalSuffixes[i % 10] : 'th';
      days.push({ value: i, displayName: `${i}${suffix}` });
    }
    return days;
  }

  getDaysUnified(days): string {
    const daysNames = [];
    const nameOfDaysOptions = [
      { value: [0], displayName: 'Sunday' },
      { value: [1], displayName: 'Monday' },
      { value: [2], displayName: 'Tuesday' },
      { value: [3], displayName: 'Wednesday' },
      { value: [4], displayName: 'Thursday' },
      { value: [5], displayName: 'Friday' },
      { value: [6], displayName: 'Saturday' }
    ];
    
    days?.forEach(
      day => {
        daysNames.push(nameOfDaysOptions.find(el => el?.value?.includes(day))?.displayName)
      }
    );
    return daysNames?.join(', ');
  }

  getStatusLabel(status): string {
    let label = status;

    switch(status) {
      case 'FLAGGED_FOR_DELETE':
        label = 'Pending Delete'
        break;
      case 'INVALID_SUBSCRIPTION':
        label = 'Invalid Subscription'
        break;
      case 'CONTACT_SUPPORT':
        label = 'Contact Us'
        break;
      case 'SUCCESS':
        label = 'Published'
        break;
    };

    return label
  }

  getModalText(data): string {
    let msg = `
      <div class="txt--md txt--left">
        <p class="mb--30">This location has failed Google validation for the following reason: </p>`

        data?.data[0]?.error?.forEach(el => {
          el?.errorDetails?.forEach(detail => {
            msg += `
              <p class="fw--medium mt--5 mb--5">${this._parseField(detail?.field)}</p>
              <p class="m--0">${detail?.message}</p>
            `;
          } )
          
        });
        msg += ` 
        <p class="mt--30">Review the location then try again. If the problem persist, please contact Support.</p>
      </div>`;
    return msg
  }

  private _parseField(field): string {
    if (!field) return '';
    let formattedString = field.replace(/[_\.]/g, ' ');
    
    formattedString = formattedString.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedString;
  }
  
}

