// dep
import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';

import { BehaviorSubject, Observable, forkJoin, Subject, of } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

// app
import { IMediaUrl } from 'src/app/constants/media-url';
import { Messages, string_message, formatFileSizes } from '../constants/messages';
import { PostService } from './post.service';
import { SnackbarService } from './snackbar.service';
import { ImageRequirement } from '../constants/google-media';
import { TypeImages } from '../constants/types';

export interface IMediaFile {
  file: any;
  dir: string;
  type: TypeImages;
  preview: string | null
}

export interface IUrl {
  url?: string;
  preview?: string;
  type?: TypeImages;
  fileName?: string;
  error?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class StorageImageService {
  // emits an IURL with the url of the file after it was uploaded to Storage
  private _url = new BehaviorSubject<IUrl | null>(null);
  private _ref = new BehaviorSubject<AngularFireStorageReference | null>(null);
  private _percent = new BehaviorSubject(0);
  private _singleFile = true;
  private _imageUrl$ = new BehaviorSubject<string | null>(null);

  // TODO: This is a multi-file version of _url, unify
  private _multipleMediaUrl: IMediaUrl[] = [];
  private _multipleMediaUrl$ = new BehaviorSubject<IMediaUrl[] | null>(null);
  private _errorsArray: string[] = [];
  private _failedMediaNames: string[] = [];
  private _failedMediaArray$ = new Subject<string[]>();

  private _fileDeletedSource$ = new Subject<void>();
  public fileDeleted$ = this._fileDeletedSource$.asObservable();

  constructor(
    private _afstorage: AngularFireStorage,
    private _snackService: SnackbarService,
    private _postS: PostService,
  ) {}

  get url$(): Observable<IUrl> {
    return this._url.asObservable();
  }

  get url(): IUrl {
    return this._url.getValue();
  }

  get ref$(): Observable<AngularFireStorageReference> {
    return this._ref.asObservable();
  }

  get percent$(): Observable<number> {
    return this._percent.asObservable();
  }
  get multipleMediaUrl$(): Observable<IMediaUrl[]> { // not working as intended
    return this._multipleMediaUrl$.asObservable();
  }

  get failedMediaArray$(): Observable<string[]> {
    return this._failedMediaArray$.asObservable();
  }

  public setMultipleMediaUrl(newArray : IMediaUrl[]): void {
    this._multipleMediaUrl = newArray;
    this._multipleMediaUrl$.next(newArray);
  }

  setUrl(url : IUrl): void {
    this._url.next(url);
  }
  
  getImageUrl(): Observable<string> {
    return this._imageUrl$.asObservable();
  }
  
  setImageUrl(url : string): void {
    this._imageUrl$.next(url);
  }

  private async _filesValidation(files: FileList, requirements): Promise<{file : File, preview : string | null}[]> {
    const validatedFiles = [];
    
    if (!files[0]) {
      if (this._singleFile) {
        this._url.next(null);
      } else {
        this._multipleMediaUrl = [];
        this._multipleMediaUrl$.next(this._multipleMediaUrl);
      }
      return validatedFiles; // Return an empty array if no files
    }
    
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const type = (file.type.startsWith('video') ? 'VIDEO' : 'PHOTO');

      if (this._validateTypeSize(requirements, file)) {
        if (this._singleFile) {
          this._url.next({error: true});
        } 
      } else {
        if (type === 'PHOTO' ) {
          const img = new Image();
          img.src = window.URL.createObjectURL(file);
          
          const imgOnLoad = new Promise((resolve, reject) => {
            img.onload = () => {
              if (this._validateFormat(requirements, img, file) ||
                  this._validateType(type, file.name)) {
                if (this._singleFile) {
                  this._url.next({error: true});
                } 
                reject();
              } else {
                validatedFiles.push({file, preview: null});
                resolve(true);
              }
            };
          });
          
          try {
            await imgOnLoad;
          } catch (error) {
            console.error('Error occurred during photo validation:', error);
          }
          
        } else {
          const videoOnLoad = new Promise(async (resolve, reject) => {
            try {
              const r = await this._getFileBase64(files[i]);
              if (this._validateType(type, files[i].name)) {
                if (this._singleFile) {
                  this._url.next({ error: true });
                } 
                reject(new Error("Invalid video type"));
              } else {
                let blob = new Blob([r], { type: files[i].type });
                let url = URL.createObjectURL(blob);
                let video = document.createElement('video') as any;

                let timeupdate = () => {
                  if (snapImage()) {
                    cleanup();
                    resolve(true);
                  }
                };

                video.addEventListener('loadeddata', () => {
                  if (snapImage()) {
                    cleanup();
                    resolve(true);
                  }
                });

                const snapImage = () => {
                  let canvas = document.createElement('canvas');
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                  let dataURI = canvas.toDataURL();
                  let success = dataURI.length > 100000;
                  if (success) {
                    validatedFiles.push({ file: files[i], preview: dataURI });
                    resolve(true);
                  }
                  return success;
                };

                const cleanup = () => {
                  video.removeEventListener('timeupdate', timeupdate);
                  video.pause();
                  video.src = "";
                  video.load();
                  URL.revokeObjectURL(url);
                };

                video.onerror = () => {
                  cleanup();
                  reject(new Error("Video failed to load"));
                };

                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                video.muted = true;
                video.playsInline = true;
                video.play();
              }
            } catch (error) {
              reject(error);
            }
          });

          try {
            await videoOnLoad;
          } catch (error) {
            console.error('Error occurred during video validation:', error);
          }
        }
      }

    }
    return validatedFiles;
  }

  fileChanged(event: HTMLInputElement, singleFile: boolean, requirements? : ImageRequirement, 
              locationId?: string, maxNumberPhotos? : number) : void {
    if (!locationId) {
      locationId = 'media';
    }
    
    if(!singleFile && maxNumberPhotos === 1) {
      this.setMultipleMediaUrl([]);
    }

    this._errorsArray = []; // reset errors
    this._failedMediaNames = [];
    this._singleFile = singleFile; // set the flag for single file
    

    // Call filesValidation asynchronously and handle the result
    this._filesValidation(event.files, requirements).then(validatedFiles => {
      this._showErrors();
      const formattedFiles: IMediaFile[] = validatedFiles.map(({file, preview}) => (
        { file, 
          dir : `public/${locationId}/${+new Date()}-${file.name}`, 
          // FIXME: type should be "PHOTO" | "VIDEO" but is file type (mime type)
          type : file.type as any, 
          preview
        }))

      // finished processing all files
      this._uploadFiles(formattedFiles)
    }).catch((error) => {
      this._showErrors();
      console.error('Error occurred during file validation:', error);
    });
  }

  removeAllFilesFromArray() : void  {
    this._multipleMediaUrl = [];
    this._multipleMediaUrl$.next([]);
  }

  removeFileFromArray(files: IMediaUrl[], file: IMediaUrl): void {
    // gets a file and an array, emits the new array without the file
    const newArray = files.filter(f => f !== file)
    this._multipleMediaUrl = newArray;
    this._multipleMediaUrl$.next(newArray);
  }

  notifyFileDeleted() : void {
    this._fileDeletedSource$.next();
  }

  reset(): void {
    this._url.next(null)
    this._multipleMediaUrl = [];
    this._multipleMediaUrl$.next([]);
  }

  private _transformWebpToJpgAndUpload(file : File, dir : string, url): void {
    this._postS.transformImage(url)
      .subscribe(res => {
        const name = _.head(_.split(file.name, '.'));
        const base64Image = 'data:image/jpg;base64,' + res.data;
        const newFile  = this._dataURLtoFile(base64Image, name);
        const placeId = dir.split('/')[1];
        const newDir = `public/${placeId}/${newFile.name}`;
        this._uploadFiles([{file: newFile, dir: newDir, type: 'PHOTO', preview: null}]);
      });
  }

  private _uploadFiles(files: IMediaFile[]): void { // review this method as it appears to be emitting the URL after the upload
    const observables: Observable<void>[] = [];

    files.forEach(element => {
      observables.push(
        new Observable(observer => {

          const fileName = element?.file?.name;
          const file = element?.file;
          const dir = element?.dir;
          const type = element?.type;
          const preview = element?.preview;

          // Finally UPLOAD the file to Firebase Storage
          this._afstorage.upload(dir, file).snapshotChanges().pipe(
            map(actions => {
              actions.task.catch((error) => {
                if (this._singleFile) {
                  this._url.next(null) // single file error
                } else {
                  this._multipleMediaUrl.push({url: '', category: type, error: true})
                }
                const msg = `An error occurred uploading the file ${fileName ? (' ' + fileName + ',') : ''} please try again later.`;
                this._failedMediaNames.push(fileName);
                this._errorsArray.push(msg);
                observer.error(error);
              })
            }),
            finalize(() => {
              const fileRef = this._afstorage.ref(dir);
              this._ref.next(fileRef);
              fileRef.getDownloadURL().subscribe(url => {
                const element = {
                  url,
                  preview,
                  type,
                  fileName,
                  category: type
                }

                if(type === 'PHOTO') {
                  if(this._filenameIsImageWebp(file.name)) {
                    this._transformWebpToJpgAndUpload(file, dir, url)
                  } else if(this._singleFile) { 
                    this._url.next(element);
                  } else {
                    this._multipleMediaUrl.push(element);
                  }                  
                } else if (this._singleFile) {
                  this._url.next(element) // single file success
                } else {
                  this._multipleMediaUrl.push(element) // multiple file success
                }
                this._multipleMediaUrl$.next([...this._multipleMediaUrl]);
              });
              observer.complete();
              
            })
          ).subscribe();
        })
      )
    });

    forkJoin(observables).pipe(
      catchError(error => {
        console.error('error when attempting to upload the file to firebase', error);
        return of(null);
      })
    ).subscribe();
  }


  private _filenameIsVideo(fileName: string): boolean {
    return (fileName.includes('.mp4') || fileName.includes('.MP4')
      || fileName.includes('.avi') || fileName.includes('.AVI'));
  }

  private _filenameIsImage(fileName: string): boolean {
    return (fileName.includes('.png') || fileName.includes('.PNG')
      || fileName.includes('.jpg') || fileName.includes('.JPG')
      || fileName.includes('.jpeg') || fileName.includes('.JPEG')
      || fileName.includes('.webp') || fileName.includes('.WEBP')
      || fileName.includes('.ico') || fileName.includes('.ICO'));
  }

  private _filenameIsImageWebp(fileName: string): boolean {
    return (fileName.includes('.webp') || fileName.includes('.WEBP'));
  }

  private async _getFileBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise( (resolve, reject) => {
      const reader = new FileReader();

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private _dataURLtoFile(dataurl : string, filename : string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const type = _.last(mime.split('/'));
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${type}`, {type: mime});
  }

  private _validateTypeSize(requirements, file: File): boolean {
    if(!requirements) {
      return false
    } else if (_.isArray(requirements.type) && !_.includes(requirements.type, file.type)) {
      const formattedTypes = requirements.type.map(t => _.last(_.split(_.kebabCase(t), '-')).toUpperCase());
      //const formattedTypes = requirements.type.map(t => t.toUpperCase());
      const lastType = formattedTypes.pop();
      const typeString = formattedTypes.length ? formattedTypes.join(', ') + ' or ' + lastType : lastType;
      const plural = requirements.type.length > 1 ? 's' : '';
      
      //const msg = `${file.name}: ${Messages.upload.BAD_TYPES.replace('{0}', typeString).replace('{1}', plural)}`;
      const msg = `${file.name}: ${string_message(
        Messages.upload.BAD_TYPES,
        [ typeString, plural ]
      )}`;
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
      return true;

    } else if (!_.isArray(requirements.type) && file.type !== requirements.type) {
      const msg = `${file.name}: ${Messages.upload.BAD_TYPE}`;
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
      return true;

    } else if (requirements.min_size && file.size < requirements.min_size) {
      const msg = `${file.name}: ${string_message(Messages.upload.BAD_SIZE_CUSTOM,
                                                 [ formatFileSizes(requirements.min_size) ])}`;
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
      return true;

    } else if (requirements.max_size && file.size > requirements.max_size) {
      const msg = `${file.name}: ${string_message(Messages.upload.BAD_MAX_SIZE_CUSTOM,
                                                  [ formatFileSizes(requirements.max_size) ])}`;
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg, 5000); // modify to work with multiple files
      return true;

    } else {
      return false
    }
  }

  private _validateFormat(requirements, img: HTMLImageElement, file: File): boolean {
    const ratio = img.width / img.height;

    if (requirements?.min_height && requirements.min_width && file.type !== 'video/mp4') {
      if (img.height < requirements.min_height || img.width < requirements.min_width) {
        const msg = `${file.name}: ${string_message(
          Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM,
          [ requirements.min_width, requirements.min_height]
        )}`;
        this._failedMediaNames.push(file.name);
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    }

    if (requirements?.height && requirements.width && file.type !== 'video/mp4') {
      if (img.height > requirements.height || img.width > requirements.width) {
        const msg = `${file.name}: ${string_message(
          Messages.upload.BAD_DIMENSION_MINIMUM_CUSTOM, // <-- might be the wrong constant, we need to check
          [ requirements.width, requirements.height]
        )}`;
        this._failedMediaNames.push(file.name);
        this._errorsArray.push(msg); 
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    } else if (img.height > 1192 || img.width > 2120) {
      const msg = `${file.name}: ${Messages.upload.BAD_DIMENSION}`;
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(Messages.upload.BAD_DIMENSION,  4000); // modify to work with multiple files
      return true;
    } else if (requirements?.minRatio && ratio < requirements.minRatio) {
      const msg = `${file.name}: Image ratio must be ${requirements.minRatio == 1.3 ? '4:3' : '16:9'}. Please edit and retry or upload a new image.`
      this._failedMediaNames.push(file.name);
      this._errorsArray.push(msg);
      // this.snackService.openWarning(msg,  4000); // modify to work with multiple files
      return true;
    }
    return false;
  }

  private _validateType(type: TypeImages , name: string): boolean {
    if (type === 'VIDEO') {
      // its a video
      if (!this._filenameIsVideo(name)) {
        const msg = `${name}: Incorrect format for a video.`;
        this._failedMediaNames.push(name);
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    } else {
      // its a photo
      if (!this._filenameIsImage(name)) {
        const msg = `${name}: Incorrect format for an image.`;
        this._failedMediaNames.push(name);
        this._errorsArray.push(msg);
        // this.snackService.openWarning(msg, 4000); // modify to work with multiple files
        return true;
      }
    }
    return false;
  }

  private _showErrors(): void {
    const combinedErrors = this._errorsArray.join('\n');
    if (combinedErrors) {
      this._failedMediaArray$.next(this._failedMediaNames);
      this._snackService.openWarning(combinedErrors, 6000, true);
    }
  }

}
