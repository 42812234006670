// dep
import {Component, Inject } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

// app
import {GoogleService} from '../../services/google.service';
import {ReviewsService} from '../../services/reviews.service';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'app-reply-edit',
  templateUrl: './reply-edit.component.html',
  styleUrls: ['./reply-edit.component.scss']
})
export class ModalReplyReviewComponent {

  comment = new FormControl('', Validators.required);
  readonly data: any;
  isEdit: boolean;
  activeDelete: boolean;
  title: string;
  reviewer: any;
  readOnly: boolean;
  actionText: any;
  profilePhoto: any;
  progress: boolean;
  confirmDelete = false;
  color: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private config,
    private googleS: GoogleService,
    private reviewS: ReviewsService,
    private dialog: MatDialogRef<ModalReplyReviewComponent>,
    private modalS: ModalService,
  ) {

    this.data = config.data;

    if (this.data.activeDelete) {
      this.activeDelete = true;
    }
    this.googleS.getProfilePhoto(this.data.accountId, this.data.locationId).subscribe(result => {
      this.profilePhoto = result;
    });


    this.reviewer = this.data.reviewer;
    this.comment.setValue(this.data?.reviewReply?.comment || this.data?.reviewReply);

    if (this.data.reviewReply) {
      this.isEdit = true;
      this.title = 'edit reply';
      this.actionText = 'update';
    } else {
      this.isEdit = false
      this.title = 'reply review';
      this.actionText = 'reply';
    }
  }

  onApply() : void {
    if (this.comment.invalid) {
      return;
    }

    this.progress = true;
    this.reviewS.reply( this.data.accountId, this.data.locationId, this.data.reviewId, this.comment.value).subscribe(
      result => {
        this.progress = false;
        if (result) {
          delete this.data.error;
          this.dialog.close(this.comment.value);
          return;
        }
        this.dialog.close(false);
      },
      err => {
        this.dialog.close(false);
      });
  }


  async handleConfirmDelete() : Promise<void> {
    if(await this.modalS.openConfirmModal('Delete Reply', '')) {
      try {
        this.progress = true;
        await this.reviewS.replyDelete(this.data.accountId, this.data.locationId, this.data.reviewId).toPromise()
        this.dialog.close(true);
      } catch(err) {
        this.dialog.close(false);
      } finally {
        this.progress = false;
      }
    }
  }

}
