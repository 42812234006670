// dep
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

import {Subject} from 'rxjs';
import * as _ from 'lodash';

// app
import { CHECKBOX_DATA, FilterCheckbox, SIZE_OPTIONS } from '../../constants/filter-reviews';
import { ReviewsService } from '../../services/reviews.service';
import { Pagination } from '../../constants/api-response';
import { ModalService } from '../../services/modal.service';
import { ModalReplyReviewComponent } from '../reply-edit/reply-edit.component';
import { Sort } from 'src/app/constants/sort';
import { LocationService } from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
//import { PdfService } from 'src/app/services/pdf.service';
import { AlertComponent, AlertType } from 'src/app/components/alert.component';
import { locationNameToRef, LocationRef } from 'src/app/constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-reports-filter-table',
  templateUrl: './reports-filter-table.component.html',
  styleUrls: ['./reports-filter-table.component.scss']
})

export class ReportsFilterTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() pagination: Pagination;
  @Input() placeId: any[];
  @Input() isReport: boolean;
  @Input() reportName = null;
  @Input() isShared = false;
  @Input() refreshFilter;
  @Input() isExporting = false;
  @ViewChild(MatPaginator, { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('inputSearch', {read: true, static: true}) inputSearch;

  isChecked: boolean;
  checkboxComponent: FilterCheckbox[];
  withResponse: boolean = null;
  withComment: boolean = null;
  displayedColumns: string[];
  sizeOption = SIZE_OPTIONS;
  dataSource: MatTableDataSource<any[]>;
  errorMessage: boolean;
  searchText = '';
  isLoading = false;
  isSingleReview: boolean;
  public _searchSubject = new Subject<string>();
  public sort: Sort = {
    sortBy: 'createTime',
    direction: null
  };
  searchTextProgress: boolean;
  ratingValue: string;
  startDate: string;
  endDate: string;
  private accountId: string;
  private locationId: string;
  public statusOptions = [
    { displayName: 'New', value: 'ACTIVE', checked: false },
    { displayName: 'Updated', value: 'UPDATED', checked: false },
    { displayName: 'Removed', value: 'DELETED', checked: false }
  ];
  public respondedOptions = [
    { displayName: 'Responded', value: true, checked: false },
    { displayName: 'Unresponded', value: false, checked: false }
  ];
  public commentsOptions = [
    { displayName: 'With comment', value: true, checked: false },
    { displayName: 'Without comment', value: false, checked: false }
  ];
  public filename = null;
  public showSpinner = false;

  constructor(
    private router: Router,
    private detectStrategy: ChangeDetectorRef,
    private reviewsS: ReviewsService,
    private modalS: ModalService,
    private route: ActivatedRoute,
    private locationS: LocationService,
    private _sessionS: SessionService,
    private loadingService: LoadingService,
    private snack: SnackbarService,
    // private pdfS: PdfService,
    public dialog: MatDialog
  ) {
    this.accountId  = this.route.parent.snapshot.params.accountId;
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.checkboxComponent = CHECKBOX_DATA;
    this.pagination = {page: 1, pageSize: 10, items: [], hasNext: false, hasPrev: false, total: 0, pages: 0};
    this.ratingValue = 'Rating';
  }

  ngOnInit(): void {
    this.setDisplayColumns();
    this._searchSubject
      .pipe
      // debounceTime(1000)
      ()
      .subscribe(result => {
        this.searchText = result;
        this.applyChanges();
      });

    this.refreshFilter?.subscribe(result => {
      const {start, end} = result.range;
      this.startDate = start;
      this.endDate = end;

      this.applyChanges();
    });

    if (!this.refreshFilter) 
      this.applyChanges();
  }

  getFilterData( placeId: string[], page: number, size: number, stars = null,filterStatus = [], hasResponse = null, hasComment = null, 
    keyword = null, start = null, end = null
  ): void {
    this.isLoading = true;
    this.searchTextProgress = true;
    const {gid} = this._sessionS.getSession();
    this.reviewsS.filter(placeId, page, size, stars, filterStatus, hasResponse, 
                         hasComment, keyword, this.sort, gid, start, end).subscribe(
      result => {
        this.setData(result);
        this.searchTextProgress = false;
        this.loadingService.reportAdvanced(1, null);
      },
      err => {
        this.searchTextProgress = false;
        this.isLoading = false;
        this.snack.openError('There was an error while loading the data. Please try again or contact support');
      }
    );
    this.isChecked = false;
  }

  setData(result): void {
    this._formatData(result?.items);
    this.pagination = result;
    this.dataSource = new MatTableDataSource(this.pagination?.items as any[]);
    this.isLoading = false;
  }

  private async _formatData(data): Promise<void> {
    this.filename = this.isReport ? this.reportName : `${data[0]?.locationName}`;
    if (!this.isReport && data[0]?.locationAddress?.hasOwnProperty('addressLines')) {
      this.filename = `${this.filename} - ${data[0]?.locationAddress?.addressLines[0]}`;
    }

    const locationsRefs : LocationRef[] = []
    data.forEach(item => {
      const source = _.split(item.name, '/');
      locationsRefs.push({
        accountId: source[1],
        locationId: source[3],
      });
    });
    const locations = await this.locationS.fetchMultipleLocations(this._sessionS.getSession().gid, locationsRefs)
    for (const item of data) {
      const location = _.find(locations, {locationId: item.locationId});
      item['mapsUrl'] = _.get(location, 'location.metadata.mapsUrl', null);
    }
  }

  search($event): void {
    if ($event.key === 'Enter') {
      this._searchSubject.next($event.target.value);
    }
  }

  // completes the date string with time 00:00:00 to be used in the endpoint payload
  formatDateString(dateString: string): string {
    if (dateString.includes('T')) {
      return dateString;
    }
    if (!dateString.includes(' ')) {
      return `${dateString} 00:00:00`;
    }
    return dateString;
  }

  async handleExport(id = null) {
    const startDate = this.formatDateString(this.startDate);
    const endDate = this.formatDateString(this.endDate);
    console.log(startDate, endDate);
    // const startDate = this.isReport ? this.startDate : `${this.startDate} 00:00:00`;
    // const endDate = this.isReport ? this.endDate : `${this.endDate} 00:00:00`;

    id = id ? [id] : null;
    await this.reviewsS.handleExportCsv(
      this.placeId,
      this.pagination.page,
      this.pagination.pageSize,
      this.filterStarsChecked(this.checkboxComponent),
      this.getFilterReviewStatus(),
      this.withResponse,
      this.withComment,
      this.searchText,
      this.sort,
      this._sessionS.getSession().gid,
      startDate,
      endDate,
      this.filename,
      id
    );
  }

  ngAfterViewInit(): void {
    this.errorMessage = false;
    this.detectStrategy.detectChanges();
  }

  // apply changes
  applyChanges(): void {
    this.setHasResponded();
    this.setHasComment();
    const startsCheck = this.filterStarsChecked(this.checkboxComponent);
    this.pagination.page = 1;
    this.dataSource = null;
    this.getFilterData(
      this.placeId,
      this.pagination.page,
      this.pagination.pageSize,
      startsCheck,
      this.getFilterReviewStatus(),
      this.withResponse,
      this.withComment,
      this.searchText,
      this.startDate,
      this.endDate
    );
  }

  getFilterReviewStatus() : string[] {
    return this.statusOptions.filter(el => el.checked).map(el => el.value);
  }

  /**
   * this method return filterCheckbox with checked equals to true
   */
  filterStarsChecked(stars: FilterCheckbox[]) {
    const startsCheck = [];
    this.ratingValue = 'Rating';
    stars.forEach(check => {
      if (check.isChecked === true) {
        startsCheck.push(check.value);
      }
    });
    this.ratingValue = (startsCheck.length === 5) ? 'All' : (startsCheck.length === 0 ? 'Rating' : startsCheck.sort().join());
    return startsCheck.sort();
  }

  /**
   * reset filters and get data
   */
  resetFilter() {
    // reset checkbox
    this.searchText = null;
    this.statusOptions.forEach(el => el.checked = false);
    this.commentsOptions.forEach(el => el.checked = false);
    this.respondedOptions.forEach(el => el.checked = false);
    this.withResponse = null;
    this.withComment = null;
    this.checkboxComponent.forEach(value => {
      value.isChecked = false;
    });
    this.ratingValue = 'Rating';

    this.pagination.page = 1;
    this.applyChanges();
  }

  /**
   * change to previous page
   */
  onPrev() {
    if (!this.pagination.hasPrev) {
      return;
    }
    this.onChangePage(this.pagination.page - 1);
  }

  /**
   * change a next page
   */
  onNext() {
    if (!this.pagination.hasNext) {
      return;
    }
    this.onChangePage(this.pagination.page + 1);
  }

  /**
   * this method change page and consume http resource that filter reviews
   * @param $event
   */
  onChangePage($event: any) {
    if ($event instanceof Object) {
      this.pagination.pageSize = $event.pageSize;
      this.onChangePage(1);
      return;
    }
    this.pagination.page = $event;
    this.dataSource = new MatTableDataSource<any[]>([]);
    const startsCheck = this.filterStarsChecked(this.checkboxComponent);
    this.getFilterData(
      this.placeId,
      this.pagination.page,
      this.pagination.pageSize,
      startsCheck,
      this.getFilterReviewStatus(),
      this.withResponse,
      this.withComment,
      this.searchText,
      this.startDate,
      this.endDate
    );
  }

  /**
   * this method handle change text search keyword comment or reviewer
   * @param searchText
   */
  handleSearchText(searchText: string) {
    this.searchText = searchText;
    this.applyChanges();
  }

  /**
   * This method choose columns depend from activated route
   */
  setDisplayColumns() {
    if (this.router.url.includes('reviews')) {
      this.displayedColumns = ['rating', 'date', 'response', 'status', 'reviewer', 'actions'];
      this.isSingleReview = false;
    } else {
      if (this.isShared) {
        this.displayedColumns = ['company', 'rating', 'date', 'response', 'status', 'reviewer'];
      } else {
        this.displayedColumns = ['company', 'rating', 'date', 'response', 'status', 'reviewer', 'actions'];
      }
      this.isSingleReview = true;
    }
  }

  ngOnDestroy(): void {
    this._searchSubject.next('');
    this._searchSubject.unsubscribe();
  }

  async openUpdatedModal(row: any) : Promise<void> {
    try {
      this.showSpinner = true;
      const res = await this.reviewsS.getUpdatedData(row?.gid, row?.accountId, row?.locationId, row?.reviewId)
      this.showSpinner = false;
      const data = res?.data;

      await this.modalS.openModal(AlertComponent,{
          title: '',
          content:  this.getModalText(data, row),
          closeButtonLabel: 'Ok',
          alertType: AlertType.INFO
      }, { config : { width: '825px'} })
    } catch(err) {
      this.snack.openError('There was an error while loading the data. Please try again or contact support')
      this.showSpinner = false;
    }
  }

  getUpdatedTime(time) {
    let date = '-';
    if (time) {
      let formattedDate = time.includes('T') ? time.split('T')[0] : time.split(' ')[0];
      formattedDate = formattedDate.split('-');
      date = `${formattedDate[1]}-${formattedDate[2]}-${formattedDate[0]}`;
    }

    return date;
  }

  getModalText(data, row) {
    const originalStars = Array.apply(null, Array(Math.floor(data?.originalReview?.starRatingNumber)).map((x, i) => i + 1));
    const originalStarsHtml = originalStars.map(_ => '<span class="single-star single-star--full"></span>').join('');

    const updatedStars = Array.apply(null, Array(Math.floor(data?.updatedReview?.starRatingNumber)).map((x, i) => i + 1));
    const updatedStarsHtml = updatedStars.map(_ => '<span class="single-star single-star--full"></span>').join('');

    return `<div class="row m--0 dialog--padding border-bottom">
      <div class="col-12 col-md-3 pt--20 pb--10 border-right"><h3 class="fw--medium m--0"></h3></div>
      <div class="col-12 col-md-4 pt--20 pb--10 border-right"><h3 class="fw--medium m--0">Original Review</h3></div>
      <div class="col-12 col-md-4 pt--20 pb--10"><h3 class="fw--medium m--0">Updated Review</h3></div>
    </div>
    <div class="dialog-row dialog--padding">
      <div class="row m--0">
        <div class="col-12">
        <div class="row border-bottom">
            <div class="col-12 col-md-3 border-right">
              <p class="mt--10 mb--10">Star Rating</p>
            </div>
            <div class="col-12 col-md-4 mt--10 mb--10 border-right">
              ${originalStarsHtml}
            </div>
            <div class="col-12 col-md-4 mt--10 mb--10">
              ${updatedStarsHtml}
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-md-3 border-right">
              <p class="mt--10 mb--10"> Review Date</p>
            </div>
            <div class="col-12 col-md-4 border-right">
              <p class="mt--10 mb--10">${this.getUpdatedTime(row?.createInMongo)}</p>
            </div>
            <div class="col-12 col-md-4">
              <p class="mt--10 mb--10">${this.getUpdatedTime(row?.updateTime)}</p>
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-md-3 border-right">
              <p class="mt--10 mb--10">Review</p>
            </div>
            <div class="col-12 col-md-4 border-right">
              <p class="mt--10 mb--10">${data?.originalReview?.comment || '-'}</p>
            </div>
            <div class="col-12 col-md-4">
              <p class="mt--10 mb--10">${data?.updatedReview?.comment || '-'}</p>
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-md-3 border-right">
              <p class="mt--10 mb--10"> Reply Date</p>
            </div>
            <div class="col-12 col-md-4 border-right">
              <p class="mt--10 mb--10">${this.getUpdatedTime(data?.originalReview?.reviewReply?.updateTime)}</p>
            </div>
            <div class="col-12 col-md-4">
              <p class="mt--10 mb--10">${this.getUpdatedTime(data?.updatedReview?.reviewReply?.updateTime)}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3 border-right">
              <p class="mt--10 mb--10">Reply</p>
            </div>
            <div class="col-12 col-md-4 border-right">
              <p class="mt--10 mb--10">${data?.originalReview?.reviewReply?.comment || '-'}</p>
            </div>
            <div class="col-12 col-md-4">
              <p class="mt--10 mb--10">${data?.updatedReview?.reviewReply?.comment || '-'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>`
  }

  openModalReply(row: any): void {
    if (this.isReport) {
      this.accountId = row.accountId
      this.locationId = row.locationId
    }
    
    this.modalS.openGenericModal(ModalReplyReviewComponent,
      {
        ...row,
        accountId: this.accountId,
        locationId: this.locationId,
        activeDelete: true,
      },
      result => {
        if (result === undefined || result === '') return;
        if (!result) {
          this.snack.openError('Oops, Something went wrong! try later');
          return;
        }
        this.snack.openSuccess('Reply was sent!');
        if(result) {
          const startsCheck = this.filterStarsChecked(this.checkboxComponent);
          this.getFilterData(
            this.placeId,
            this.pagination.page,
            this.pagination.pageSize,
            startsCheck,
            this.getFilterReviewStatus(),
            this.withResponse,
            this.withComment,
            this.searchText,
            this.startDate,
            this.endDate
          );
        } else {
          this.isLoading = false;
        }
      }
    );
  }

  updateRowData(row, comment) {
    const updatedReviews = this.dataSource.data.map(r => {
      const review = r as any;
      if (review.reviewId === row.reviewId) {
        return {
          ...review,
          reviewReply: {
            ...review.reviewReply,
            comment
          }
        };
      }
      return review;
    }) as any;
    this.dataSource = new MatTableDataSource<any[]>(updatedReviews);
  }

  setHasResponded() {
    const checked = this.respondedOptions.filter(el => el.checked);
    this.withResponse = !checked.length || checked.length == 2 ? null : checked[0]?.value;
  }

  setHasComment() {
    const checked = this.commentsOptions.filter(el => el.checked);
    this.withComment = !checked.length || checked.length == 2 ? null : checked[0]?.value;
  }

  sortBy($event) {
    this.sort = {
      sortBy: $event.active,
      direction: $event.direction
    };
    const startsCheck = this.filterStarsChecked(this.checkboxComponent);
    this.getFilterData(
      this.placeId,
      this.pagination.page,
      this.pagination.pageSize,
      startsCheck,
      this.getFilterReviewStatus(),
      this.withResponse,
      this.withComment,
      this.searchText,
      this.startDate,
      this.endDate
    );
  }

  locationUrl(location, destiny) {
    const accountId  = locationNameToRef(location).accountId;
    const locationId = location.locationId;
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  formattedStatus(status) {
    switch(status) {
      case 'ACTIVE':
        return 'new'
      case 'DELETED':
        return 'removed'
      default:
        return status
    }
  }
}

//deprecated

// custom labels for pagination
// export class CustomPaginatorFilters extends MatPaginatorIntl {
//   constructor() {
//     super();
//     this.itemsPerPageLabel = 'Items per page';
//   }

//   // change range label
//   getRangeLabel = (page: number, pageSize: number, length: number) => {
//     if (length === 0 || pageSize === 0) {
//       return `0 / ${length}`;
//     }
//     length = Math.max(length, 0);
//     const startIndex = page * pageSize;
//     const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
//     return `${startIndex + 1} - ${endIndex} of ${length} items`;
//   };

// }
